import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { strings } from '../../../constants/localization';
import {
  updateCreditFacilityDunningType,
  updateAssignedUser,
  updateLeadDunningStatus,
  getUpdatedPatientCreditFacility,
} from '../../../actions/leadActions';
import { getPatients } from '../../../actions/patientDunningActions';
import { ROUTE_PATIENTS_DUNNING } from '../../../constants/routes';
import DunningStatusDropdown from './DunningStatusDropdown';
import { dunningCauseMap } from '../../../constants/mappings';

const DunningStatusCard = (props) => {
  const { creditFacilityInformation, leadClientInformation } =
    props.currentClient;
  const location = useLocation();
  const dispatch = useDispatch();
  const listOfAdmins = useSelector((state) => state.user?.admins);
  const [cause, setCause] = useState();
  const [responsible, setResponsible] = useState('');
  const [status, setStatus] = useState('');
  const [dunningDropdownStatus, setDunningDropdownStatus] = useState(
    creditFacilityInformation.dunningStatus
  );
  const [newDunningCauseMap, setNewDunningCauseMap] = useState([]);
  const [adminsSelect, setAdminsSelect] = useState([]);

  // run only on first render with translated labels
  useEffect(() => {
    if (listOfAdmins && listOfAdmins?.length > 0) {
      const mappedAdmins = listOfAdmins.map((admin) => {
        return {
          label: admin?.fullName,
          value: admin?.userName,
        };
      });
      setAdminsSelect(mappedAdmins);
    }

    setNewDunningCauseMap(
      dunningCauseMap.map((item) => {
        return {
          label: strings[item.label],
          value: item.value,
        };
      })
    );
  }, []);

  // Set the cause object with data from credit facility
  useEffect(() => {
    newDunningCauseMap.forEach((cause) => {
      if (cause.value === creditFacilityInformation.dunningType) {
        setCause({
          label: cause.label,
          value: cause.value,
        });
      }
    });
  }, [creditFacilityInformation.dunningType, newDunningCauseMap]);

  // Set the responsible object with data from credit facility
  useEffect(() => {
    const assigned =
      adminsSelect?.length > 0
        ? adminsSelect.find(
            (admin) =>
              creditFacilityInformation?.assignedUser !== null &&
              creditFacilityInformation?.assignedUser === admin?.value
          )
        : '';
    setResponsible(assigned || '');
  }, [creditFacilityInformation.assignedUser, adminsSelect]);

  useEffect(() => {
    setDunningDropdownStatus(creditFacilityInformation.dunningStatus);
  }, [creditFacilityInformation.dunningStatus]);

  useEffect(() => {
    if (leadClientInformation.clientDunningStatus) {
      setStatus('red');
    } else if (creditFacilityInformation.creditCardState === 'ACTIVE') {
      setStatus('green');
    } else if (
      ['INACTIVE, FAILED'].indexOf(creditFacilityInformation.creditCardState) >
        -1 ||
      creditFacilityInformation.creditCardState === null
    ) {
      setStatus('yellow');
    }
  }, [
    leadClientInformation.clientDunningStatus,
    creditFacilityInformation.creditCardState,
  ]);

  const updateSearchResults = () => {
    const { searchActive } = props;
    let searchValueActive = null;

    if (searchActive && searchActive.flag === true) {
      searchValueActive = searchActive.searchInput;
    }
    if (location.pathname !== ROUTE_PATIENTS_DUNNING) {
      dispatch(getUpdatedPatientCreditFacility(creditFacilityInformation.id));
    } else {
      dispatch(getPatients(0, null, searchValueActive));
    }
  };

  const selectCause = (selectedCause) => {
    dispatch(
      updateCreditFacilityDunningType(
        creditFacilityInformation.number,
        selectedCause.value
      )
    )
      .then((result) => {
        if (result.payload.status === 200) {
          setCause(selectedCause);
          setTimeout(() => {
            updateSearchResults();
          }, 500);
        }
      })
      .catch((error) => new Error(error));
  };

  const selectAdmin = (admin) => {
    dispatch(updateAssignedUser(creditFacilityInformation.number, admin.value))
      .then((result) => {
        if (result.payload.status === 200) {
          setResponsible(admin);
          updateSearchResults();
        }
      })
      .catch((error) => new Error(error));
  };

  const dunningStatusChange = (newStatus) => {
    dispatch(
      updateLeadDunningStatus(creditFacilityInformation.number, newStatus)
    )
      .then((result) => {
        if (result.payload.status === 200) {
          setDunningDropdownStatus(newStatus);
          updateSearchResults();
        }
      })
      .catch((error) => new Error(error));
  };

  return (
    <Card
      id='DunningStatusComponent'
      className={`custom-card dunning-status-card ${status}`}
    >
      <CardBody>
        <div className='header-information'>
          <CardTitle className='header-text'>
            <div className='descriptionWraper'>
              <div className='iconArea'>
                <i className='lnil lnil-achievement green'></i>
              </div>
              <div className='titleArea'>{strings.dunningCardTitle}</div>
            </div>
          </CardTitle>
        </div>
        <div className='status-information'>
          <Row>
            <Col xs='12' lg='3'>
              <div className='dunning-title'>{strings.dunningCauseTitle}</div>
              <Select
                options={newDunningCauseMap}
                isMulti={false}
                value={cause}
                onChange={selectCause}
                placeholder={strings.dunningResponsibleDropdownPlaceholder}
                className='custom_select'
                classNamePrefix='custom_select'
              />
            </Col>
            <Col xs='12' lg='3'>
              <div className='dunning-title'>{strings.personResponsible}</div>
              <Select
                options={adminsSelect}
                isMulti={false}
                value={responsible}
                onChange={selectAdmin}
                placeholder={strings.dunningResponsibleDropdownPlaceholder}
                className='custom_select'
                classNamePrefix='custom_select'
              />
            </Col>
            <Col xs='12' lg='6'>
              <div className='dunning-title'>{strings.caseStatus}</div>
              <DunningStatusDropdown
                value={dunningDropdownStatus}
                onSelect={dunningStatusChange}
              />
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

DunningStatusCard.propTypes = {
  updateCreditFacilityDunningType: PropTypes.func,
  updateAssignedUser: PropTypes.func,
  updateLeadDunningStatus: PropTypes.func,
  getLeads: PropTypes.func,
  getPatients: PropTypes.func,
};

export default DunningStatusCard;
