import { newInvoicesStatusMap } from '../../../constants/mappings';
import { momentFormatLong } from '../../../constants';
import { currencyFormatDA } from '../../../constants/utils';
import moment from 'moment';
import React from 'react';
import { strings } from '../../../constants/localization';

interface BillingHistoryListItemProps {
  createdDate: string;
  amount: number;
  companyName: string;
  status: string;
  interestRate?: number;
}

const BillingHistoryListItem: React.FC<BillingHistoryListItemProps> = ({
  createdDate,
  amount,
  companyName,
  status,
  interestRate,
}) => {
  const paidOutDate = '';
  const shownStatus = (() => {
    switch (status) {
      case 'WAITING_FOR_SIGNATURE':
        return 'PENDING';
      case 'ACCEPTED':
        if (!paidOutDate) {
          if (interestRate === 0) {
            return 'APPROVED_ZERO_INTEREST';
          }
          return 'APPROVED';
        }
        if (paidOutDate) {
          if (interestRate === 0) {
            return 'PAYED_OUT_ZERO_INTEREST';
          }
          return 'PAYED_OUT';
        }
        break;
      case 'PAYED':
        if (paidOutDate) {
          if (interestRate === 0) {
            return 'PAYED_OUT_ZERO_INTEREST';
          }
          return 'PAYED_OUT';
        }
        break;
      case 'CONSOLIDATED':
      case 'RESTRUCTURED':
      case 'LATE_PAYMENT':
      case 'SETTLED':
        if (!paidOutDate) {
          return 'APPROVED';
        }
        if (paidOutDate) {
          return 'PAYED_OUT';
        }
        break;
      case 'NOT_ACCEPTED':
        return 'DECLINED';
      default:
        return 'UNDEFINED';
    }
    return 'UNDEFINED';
  })();

  return (
    <div id='billing-list-item' className='table-row-div'>
      <div className='table-cell-div'>
        {moment(createdDate).format(momentFormatLong).toLowerCase()}
      </div>
      <div className='table-cell-div'>{companyName}</div>
      <div
        className={`table-cell-div ${
          newInvoicesStatusMap[shownStatus] !== undefined
            ? newInvoicesStatusMap[shownStatus].className
            : ``
        }
    `}
      >
        {strings[newInvoicesStatusMap[shownStatus].statusText]}
      </div>
      <div className='table-cell-div'>
        {amount && currencyFormatDA(amount, true, true)}
      </div>
    </div>
  );
};

export default BillingHistoryListItem;
