import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { Button } from 'reactstrap';
import Slider from 'rc-slider';
import Popover from './Popover';
import { moment } from '../../constants';
import { strings } from '../../constants/localization';
import { openPopover } from '../../constants/popovers';
import {
  currencyFormatDA,
  formatInterestRate,
  paymentListMoment,
  paymentListMomentRestructureLoans,
} from '../../constants/utils';
import 'rc-slider/assets/index.css';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ErrorPage from '../common/ErrorPage';
import { creditMaxDuration } from '../../config';

const StepTwo = (props) => {
  const { acceptLoan } = props;
  const [duration, setDuration] = useState(
    acceptLoan.restructured
      ? acceptLoan.defaultDuration
      : acceptLoan.minimumDuration
  );
  const [reepayInvoiceSettled, setReepayInvoiceSettled] = useState(
    acceptLoan.reepayInvoiceSettled
  );
  const [reepayInvoiceDeleted, setReepayInvoiceDeleted] = useState(
    acceptLoan.restructured && acceptLoan.status === 'DELETED'
  );
  const [loanDetails, setLoanDetails] = useState({});
  const [sliderMarks, setSliderMarks] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({
    monthlyPayment: 0,
    apr: 0,
  });
  const [totalCreditCost, setTotalCreditCost] = useState(0);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [restructuredLoan] = useState(props.acceptLoan.restructured);
  const maxAcceptedLoanPeriod =
    acceptLoan.interestRate > 0 ? creditMaxDuration : 24;
  const isRangeSliderLocked = acceptLoan.durationLocked;
  const brandEnvVariable = process.env.REACT_APP_BRAND;

  const loansRef = useRef();
  const setupFeeRef = useRef();
  const durationRef = useRef();

  const handlePayments = (
    firstPaymentDate,
    interestRate,
    loanAmount,
    rangeDuration,
    establishmentFee
  ) => {
    const paymentResults = !restructuredLoan
      ? paymentListMoment(
          firstPaymentDate,
          parseFloat(interestRate),
          loanAmount,
          rangeDuration,
          parseFloat(establishmentFee)
        )
      : paymentListMomentRestructureLoans(
          firstPaymentDate,
          parseFloat(interestRate),
          loanAmount + establishmentFee,
          rangeDuration
        );
    setPaymentDetails(paymentResults);
  };

  const handleMonthlyPayment = (rangeDuration) => {
    let debouncedFn;

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        handlePayments(
          loanDetails.firstPaymentDate,
          loanDetails.interestRate,
          loanDetails.loanAmount + loanDetails.remainingBalanceOnExistingLoans,
          rangeDuration,
          loanDetails.establishmentFee
        );
      }, 10);
    }

    debouncedFn();
  };

  useEffect(() => {
    const maxAcceptedLoanPeriod =
      acceptLoan.interestRate > 0 ? creditMaxDuration : 24;
    if (acceptLoan !== undefined) {
      setLoanDetails(acceptLoan);
      const minimumUsedDuration = Math.max(
        acceptLoan.minimumDuration,
        acceptLoan.defaultDuration
      );

      const halfMark = Math.round(
        (maxAcceptedLoanPeriod + acceptLoan.minimumDuration) / 2
      );
      const firstMark = Math.round((halfMark + acceptLoan.minimumDuration) / 2);
      const lastMark = Math.round((maxAcceptedLoanPeriod + halfMark) / 2);

      const marks = {
        [acceptLoan.minimumDuration]: acceptLoan.minimumDuration,
        [firstMark]: {
          style: {
            fontSize: 10,
          },
          label: '|',
        },
        [halfMark]: halfMark,
        [lastMark]: {
          style: {
            fontSize: 10,
          },
          label: '|',
        },
        [maxAcceptedLoanPeriod]: maxAcceptedLoanPeriod,
      };
      // show starting mark for resturtured loans and normal loans
      if (loanDetails.restructured) {
        if (
          loanDetails.defaultDuration >= loanDetails.minimumDuration &&
          loanDetails.defaultDuration <= maxAcceptedLoanPeriod
        ) {
          setDuration(loanDetails.defaultDuration);
        }
      } else {
        setDuration(minimumUsedDuration);
      }
      setSliderMarks(marks);
      setReepayInvoiceSettled(acceptLoan.reepayInvoiceSettled);
      setReepayInvoiceDeleted(
        acceptLoan.restructured && acceptLoan.status === 'DELETED'
      );

      if (acceptLoan.firstPaymentDate !== undefined) {
        handlePayments(
          acceptLoan.firstPaymentDate,
          acceptLoan.interestRate,
          acceptLoan.loanAmount + acceptLoan.remainingBalanceOnExistingLoans,
          minimumUsedDuration,
          acceptLoan.establishmentFee
        );
      }
    }
    // eslint-disable-next-line
  }, [props.acceptLoan]);

  useEffect(() => {
    const totalRepay = duration * paymentDetails.monthlyPayment;
    setTotalRepayment(totalRepay);

    const totalCost =
      totalRepay -
      loanDetails.loanAmount -
      loanDetails.remainingBalanceOnExistingLoans;
    setTotalCreditCost(totalCost);
    // eslint-disable-next-line
  }, [duration, paymentDetails.monthlyPayment]);

  const continueStep = () => {
    const processData = {
      loanAmount:
        loanDetails.loanAmount + loanDetails.remainingBalanceOnExistingLoans,
      duration,
      setupFee: loanDetails.establishmentFee,
      interestRate: loanDetails.interestRate,
      apr: paymentDetails.apr,
      totalCreditCost,
      totalRepayment,
      dateEstablishmentFee: moment().format('DD-MM-YYYY'),
      monthlyPayment: paymentDetails.monthlyPayment,
      firstInstallmentDate: moment(
        loanDetails.firstPaymentDate,
        'YYYY-MM-DD'
      ).format('DD-MM-YYYY'),
      lastInstallmentDate: moment(loanDetails.firstPaymentDate, 'YYYY-MM-DD')
        .add(duration - 1, 'months')
        .format('DD-MM-YYYY'),
    };
    props.updateProcessData(processData);
    props.nextStep();
  };

  const rejectStep = () => {
    props.rejectLoan();
  };

  const openUserPopover = (target, type) =>
    openPopover(
      ({ close }) => {
        let popOverContent;
        switch (type) {
          case 'loans':
            popOverContent = (
              <>
                <div className='popover-title'>
                  {strings.tipExistingLoanTitle}
                </div>
                <div className='popover-text'>
                  <p>{strings.tipExistingLoanText1}</p>
                  <p>{strings.tipExistingLoanText2}</p>
                </div>
              </>
            );
            break;
          case 'setupFee':
            popOverContent = (
              <>
                <div className='popover-title'>{strings.tipSetupFeeTitle}</div>
                <div className='popover-text'>
                  <p>{strings.tipSetupFeeText1}</p>
                  <p>{strings.tipSetupFeeText2}</p>
                </div>
              </>
            );
            break;
          case 'duration':
            popOverContent = (
              <>
                <div className='popover-title'>
                  {strings.acceptLoanDuration}
                </div>
                <div className='popover-text'>
                  <p>{strings.tipDurationText1}</p>
                  <p>
                    {strings.formatString(
                      strings.tipDurationText2,
                      loanDetails.minimumDuration,
                      maxAcceptedLoanPeriod
                    )}
                  </p>
                </div>
              </>
            );
            break;
          default:
            return <div />;
        }

        return (
          <Popover>
            {popOverContent}
            <div className='popover-close'>
              <Button color='blue' onClick={() => close()}>
                OK
              </Button>
            </div>
          </Popover>
        );
      },
      target,
      { placement: 'right' }
    );

  const onSliderChange = (value) => {
    setDuration(value);
    handleMonthlyPayment(value);
  };
  let renderSituation;

  if (acceptLoan.restructured && reepayInvoiceSettled === false) {
    // reepay invoice not settled, then show loading payment message
    renderSituation = (
      <div className=' m-auto'>
        <LoadingMessage message={strings.reepayInvoiceSettleMessage} />
      </div>
    );
  } else if (
    acceptLoan.restructured &&
    !reepayInvoiceSettled &&
    reepayInvoiceDeleted
  ) {
    // error page, couldn't settle the invoice
    renderSituation = (
      <ErrorPage>
        <p>{strings.reepayInvoiceSettledError}</p>
      </ErrorPage>
    );
  } else {
    renderSituation = (
      <>
        <div id='modal-root' />
        <div className='step-content step-two'>
          <h1 className='step-title'>
            {!loanDetails.restructured
              ? strings.step2Title
              : strings.step2RestTitle}
          </h1>

          <div className='loan-details'>
            <div>
              <div>
                {!loanDetails.restructured
                  ? strings.billAmount
                  : strings.currentPrincipal}
              </div>
              <div>{currencyFormatDA(loanDetails.loanAmount, true, true)}</div>
            </div>

            {/* for restructured loans only */}
            {loanDetails.restructured &&
              loanDetails.latePaymentFee !== undefined &&
              loanDetails.latePaymentFee !== 0 && (
                <div>
                  <div>{strings.latePaymentFee}</div>
                  <div>
                    {currencyFormatDA(loanDetails.latePaymentFee, true, true)}
                  </div>
                </div>
              )}

            {/* for normal loans only  */}
            {!loanDetails.restructured &&
              loanDetails.remainingBalanceOnExistingLoans !== 0 && (
                <div ref={loansRef}>
                  <div>
                    {strings.existingLoans}
                    {loanDetails.interestRate > 0 && (
                      <i
                        className='di di-help'
                        onClick={() =>
                          openUserPopover(loansRef.current, 'loans')
                        }
                      />
                    )}
                  </div>
                  <div>
                    {currencyFormatDA(
                      loanDetails.remainingBalanceOnExistingLoans,
                      true,
                      true
                    )}
                  </div>
                </div>
              )}

            {!loanDetails.restructured ? (
              <div ref={setupFeeRef}>
                <div>
                  {strings.acceptSetupFee}
                  {loanDetails.interestRate > 0 && (
                    <i
                      className='di di-help'
                      onClick={() =>
                        openUserPopover(setupFeeRef.current, 'setupFee')
                      }
                    />
                  )}
                </div>
                <div>
                  {currencyFormatDA(loanDetails.establishmentFee, true, true)}
                </div>
              </div>
            ) : (
              <div>
                <div>{strings.restructureFees}</div>
                <div>
                  {currencyFormatDA(loanDetails.establishmentFee, true, true)}
                </div>
              </div>
            )}

            <div>
              <div>{strings.acceptInterestRate}</div>
              <div>{formatInterestRate(loanDetails?.interestRate)}</div>
            </div>
          </div>

          <div className='loan-duration'>
            <div ref={durationRef}>
              {!loanDetails.restructured ? (
                <div>
                  {strings.acceptLoanDuration}
                  {loanDetails.interestRate > 0 && (
                    <i
                      className='di di-help'
                      onClick={() =>
                        openUserPopover(durationRef.current, 'duration')
                      }
                    />
                  )}
                </div>
              ) : (
                <div>{strings.newDuration}</div>
              )}
              <div>{strings.formatString(strings.restMonths, duration)}</div>
            </div>

            {loanDetails.restructured && (
              <div>
                <div>{strings.firstInstallmentDueDate}</div>
                <div>
                  {moment(loanDetails.firstPaymentDate, 'YYYY-MM-DD').format(
                    'DD-MM-YYYY'
                  )}
                </div>
              </div>
            )}

            {!isRangeSliderLocked &&
              loanDetails.minimumDuration < maxAcceptedLoanPeriod && (
                <div className='form-group duration-slider'>
                  {brandEnvVariable === 'Denti' && (
                    <Slider
                      // tipFormatter={percentFormatter}
                      value={duration}
                      step={1}
                      min={loanDetails.minimumDuration}
                      max={maxAcceptedLoanPeriod}
                      onChange={onSliderChange}
                      marks={sliderMarks}
                      trackStyle={{ backgroundColor: '#339ca6', height: 8 }}
                      handleStyle={{
                        backgroundColor: '#d2bea3',
                        borderColor: '#ffffff',
                        height: 27,
                        width: 27,
                        marginTop: -10,
                        borderWidth: 2,
                      }}
                      railStyle={{ backgroundColor: '#f8f8f8', height: 8 }}
                    />
                  )}
                  {brandEnvVariable === 'Sundhedplus' && (
                    <Slider
                      // tipFormatter={percentFormatter}
                      value={duration}
                      step={1}
                      min={loanDetails.minimumDuration}
                      max={maxAcceptedLoanPeriod}
                      onChange={onSliderChange}
                      marks={sliderMarks}
                      trackStyle={{ backgroundColor: '#aa2d36', height: 8 }}
                      dots={false}
                      handleStyle={{
                        backgroundColor: '#d4aa79',
                        borderColor: '#ffffff',
                        height: 27,
                        width: 27,

                        borderWidth: 2,
                        marginTop: -10,
                        zIndex: 2999,
                      }}
                      railStyle={{ backgroundColor: '#f8f8f8', height: 8 }}
                    />
                  )}
                </div>
              )}
          </div>
          <div className='invoice-amount'>
            <div className='invoice-amount-label'>
              {!loanDetails.restructured
                ? strings.acceptMonthlyPayment
                : strings.futurePayment}
            </div>
            <div className='invoice-amount-value'>
              {currencyFormatDA(
                paymentDetails.monthlyPayment.toFixed(2),
                true,
                true
              )}
            </div>
          </div>
          <div className='loan-totals'>
            <div>
              <div>{strings.acceptLoanApr}</div>
              <div>{formatInterestRate(paymentDetails?.apr.toFixed(2))}</div>
            </div>
            <div>
              <div>{strings.acceptTotalCreditCost}</div>
              <div>{currencyFormatDA(totalCreditCost, true, true)}</div>
            </div>
            <div>
              <div>{strings.totalRepayment}</div>
              <div>{currencyFormatDA(totalRepayment, true, true)}</div>
            </div>
          </div>
          <div className='step-actions'>
            <Button
              color='light'
              className='reject'
              onClick={() => rejectStep()}
            >
              {strings.declineLoan}
            </Button>
            <Button
              color='blue'
              className='continue'
              onClick={() => continueStep()}
            >
              {strings.continueStep}
            </Button>
          </div>
        </div>
      </>
    );
  }
  return renderSituation;
};

export default StepTwo;
