import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { success, error } from 'react-notification-system-redux';
import { strings } from '../../constants/localization';
import {
  getUpdatedPatientCreditFacility,
  setClientLoading,
  addWsPatientToUpdate,
  openLoanDetails,
} from '../../actions/leadActions';
import { getLoanDetails } from '../../actions/installmentLoanActions';
import { clearClientWebsocketMessage } from '../../actions/websocketActions';
import {
  isAdmin,
  isManager,
  isDentist,
  addNotification,
  currencyFormatDA,
} from '../../constants/utils';
import LeadsList from './leads-list/LeadsList';
import LoadingMessage from '../statusMessages/LoadingMessage';
import LeadDetails from './lead-details/LeadDetails';
import DentiModal from '../common/DentiModal';
import WebsocketNotificationModal from '../common/WebsocketNotificationModal';
import LoanDetails from '../invoices/LoanDetails';
// eslint-disable-next-line
import WelcomePage from '../common/WelcomePage';

const Leads = ({
  toggleMenu,
  toggleModal,
  modalShow,
  toggleCreateNewInvoice,
  createNewInvoice,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentUser = useSelector((state) => state.user?.user);
  const lead = useSelector((state) => state.lead) || {};
  const { leadsList, activeLead, globalLoading } = lead;
  const [showLoanDetailsModal, setShowLoanDetailsModal] = useState(false);
  const [loanDetails, setLoanDetails] = useState(false);
  // get the ws messages for the clients page
  const wsClientMessages = useSelector(
    (state) => state.websocketMessages.client,
    shallowEqual
  );
  const [activeLeadWsMessage, setActiveLeadWsMessage] = useState({});
  const [otherLeadWsMessage, setOtherLeadWsMessage] = useState({});
  const [notificationType, setNotificationType] = useState('');

  const processWebsocketNotification = useCallback(
    (notification) => {
      // check if the ws notification is for the active patient
      const activeLeadCreditFacilityId =
        activeLead?.creditFacilityInformation?.id || null;
      const notificationCreditFacilityId =
        notification.loanDetails !== undefined
          ? notification.loanDetails?.creditFacilityId
          : notification?.creditFacilityId;
      const triggeringUser = notification?.triggeringUser;
      //show websocket notification only if the current user is not the trigerring user
      if (triggeringUser !== currentUser?.username) {
        if (
          activeLeadCreditFacilityId &&
          activeLeadCreditFacilityId === notificationCreditFacilityId
        ) {
          setActiveLeadWsMessage(notification);
          switch (notification?.websocketMessageType) {
            case 'CLIENT_INFO_UPDATE':
            case 'CREDIT_UPDATE':
              setNotificationType('patient-profile-update');
              break;
            case 'ACCEPTED_LOAN_NOTIFICATION':
              setNotificationType('invoice-accepted');
              break;
            case 'DECLINED_LOAN_NOTIFICATION':
              setNotificationType('invoice-declined');
              break;
            case 'NEW_LOAN_NOTIFICATION':
              setNotificationType('invoice-registered');
              break;
            case 'DUNNING_NOTIFICATION':
              setNotificationType('dunning');
              break;
            default:
              break;
          }
        } else {
          // show notification in the top corner if the message is not for the watched patient; don't show for admin
          if (isManager(currentUser) || isDentist(currentUser)) {
            setOtherLeadWsMessage(notification);
          }
          // save the creditFacilityIds of the patients that needs to be reloaded after ws notification
          dispatch(addWsPatientToUpdate(notificationCreditFacilityId));
        }
      }
      //only for admins and currently watched patient
      if (
        isAdmin(currentUser) &&
        activeLeadCreditFacilityId &&
        activeLeadCreditFacilityId === notificationCreditFacilityId
      ) {
        setActiveLeadWsMessage(notification);
        switch (notification?.websocketMessageType) {
          case 'CREDIT_CARD_UPDATED':
            setNotificationType('patient-profile-update');
            break;
          case 'INVOICE_REFUND':
            setNotificationType('refund');
            break;
          case 'LOAN_DELETED':
            // Case 1: if is a normal delete loan, then creditFacilityId and triggeringUser are set and triggeringUser is not the current user
            if (triggeringUser && triggeringUser !== currentUser.username) {
              setNotificationType('loan-deleted');
            }
            // Case2: if the deleted loan is due to a failing withdrawal of a partial redemption, which causes the restructuring to be deleted
            // then creditFacilityId is set, but no triggeringUser
            if (!triggeringUser) {
              setNotificationType('loan-deleted-withdrawal-failed');
            }
            break;
          default:
            break;
        }
      }
    },
    [activeLead, currentUser, dispatch]
  );

  useEffect(() => {
    // react to ws messages new entries
    if (wsClientMessages.length > 0) {
      wsClientMessages.forEach((notification, index) => {
        processWebsocketNotification(notification, index);
        dispatch(clearClientWebsocketMessage(index));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClientMessages]);

  useEffect(() => {
    if (Object.keys(otherLeadWsMessage).length > 0) {
      const wsClientFirstName =
        otherLeadWsMessage.loanDetails !== undefined
          ? otherLeadWsMessage.loanDetails?.creditFacilityClientFirstName
          : otherLeadWsMessage?.firstName ||
            otherLeadWsMessage?.clientFirstName;
      const wsClientLastName =
        otherLeadWsMessage.loanDetails !== undefined
          ? otherLeadWsMessage.loanDetails?.creditFacilityClientLastName
          : otherLeadWsMessage?.lastName || otherLeadWsMessage?.clientLastName;
      const userLink = `/leads?q=${wsClientFirstName}%20${wsClientLastName}`;
      switch (otherLeadWsMessage?.websocketMessageType) {
        case 'CLIENT_INFO_UPDATE':
        case 'CREDIT_UPDATE':
          dispatch(
            success(
              addNotification(
                strings.formatString(
                  strings.wsNotifPatientUpdateText,
                  <a href={userLink}>
                    {wsClientFirstName || ''} {wsClientLastName || ''}
                  </a>
                ),
                strings.wsNotifPatientUpdateHeadline,
                0,
                {}
              )
            )
          );
          break;
        case 'ACCEPTED_LOAN_NOTIFICATION':
          dispatch(
            success(
              addNotification(
                strings.formatString(
                  strings.invoiceAcceptMessage,
                  <a href={userLink}>
                    {wsClientFirstName || ''} {wsClientLastName || ''}
                  </a>,
                  currencyFormatDA(
                    otherLeadWsMessage?.loanDetails?.amount.toFixed(2),
                    true,
                    true
                  )
                ),
                strings.invoiceAcceptTitle,
                0
              )
            )
          );
          break;
        case 'DECLINED_LOAN_NOTIFICATION':
          dispatch(
            error(
              addNotification(
                strings.formatString(
                  strings.invoiceNotAcceptMessage,
                  <a href={userLink}>
                    {wsClientFirstName || ''} {wsClientLastName || ''}
                  </a>
                ),
                strings.invoiceNotAcceptTitle,
                0,
                {}
              )
            )
          );
          break;
        case 'NEW_LOAN_NOTIFICATION':
          dispatch(
            success(
              addNotification(
                strings.formatString(
                  strings.wsNotifNewInvoiceText,
                  currencyFormatDA(
                    otherLeadWsMessage?.loanDetails?.amount.toFixed(2),
                    true,
                    true
                  ),
                  <a href={userLink}>
                    {wsClientFirstName || ''} {wsClientLastName || ''}
                  </a>
                ),
                strings.wsNotifNewInvoiceHeadline,
                0
              )
            )
          );
          break;
        default:
          break;
      }
    }
  }, [otherLeadWsMessage, dispatch]);

  const loadAndUpdatePatient = useCallback(
    (creditId, type) => {
      dispatch(setClientLoading(true));
      dispatch(getUpdatedPatientCreditFacility(creditId)).then(() => {
        dispatch(setClientLoading(false));
        if (type === 'dunning') {
          dispatch(openLoanDetails());
        }
      });
    },
    [dispatch]
  );

  const openLoanModalAfterRefund = useCallback(() => {
    dispatch(setClientLoading(true));
    dispatch(getUpdatedPatientCreditFacility()).then(() => {
      dispatch(setClientLoading(false));
      if (activeLeadWsMessage?.installmentLoanNumber !== null) {
        dispatch(getLoanDetails(activeLeadWsMessage.installmentLoanNumber))
          .then((result) => {
            if (result.payload.data) {
              setShowLoanDetailsModal(true);
              setLoanDetails(result.payload.data);
            }
          })
          .catch((error) => new Error(error));
      }
    });
  }, [activeLeadWsMessage, dispatch]);

  const renderNotificationModal = useCallback(() => {
    if (!notificationType || notificationType === '') {
      return null;
    }

    const notificationCreditFacilityId =
      activeLeadWsMessage.loanDetails !== undefined
        ? activeLeadWsMessage.loanDetails?.creditFacilityId
        : activeLeadWsMessage?.creditFacilityId;
    return (
      <WebsocketNotificationModal
        type={notificationType}
        patientData={activeLeadWsMessage}
        close={() => {
          loadAndUpdatePatient(notificationCreditFacilityId);
          setNotificationType('');
        }}
        openLoanModal={openLoanModalAfterRefund}
      />
    );
  }, [
    activeLeadWsMessage,
    loadAndUpdatePatient,
    notificationType,
    openLoanModalAfterRefund,
  ]);

  return (
    <>
      {globalLoading && <LoadingMessage />}
      <Row>
        <Col xs='12' md='12' xl='4' className='entities-list-section p-0'>
          <LeadsList
            activeLead={activeLead}
            toggleMenu={toggleMenu}
            location={location}
            history={history}
          />
        </Col>
        <Col
          xs='12'
          md='12'
          xl='8'
          className={`entity-details-section ${
            activeLead !== undefined ? 'active' : ''
          }`}
        >
          {leadsList && leadsList.content.length > 0 ? (
            <>
              {activeLead !== undefined ? (
                <LeadDetails toggleMenu={toggleMenu} />
              ) : (
                <WelcomePage
                  toggleModal={toggleModal}
                  modalShow={modalShow}
                  toggleCreateNewInvoice={toggleCreateNewInvoice}
                  createNewInvoice={createNewInvoice}
                />
              )}
            </>
          ) : (
            <WelcomePage
              toggleModal={toggleModal}
              modalShow={modalShow}
              toggleCreateNewInvoice={toggleCreateNewInvoice}
              createNewInvoice={createNewInvoice}
            />
          )}

          {/* RENDER WEBSOCKET NOTIFICATIONS MODALS  */}
          {notificationType !== '' && renderNotificationModal()}

          {/* RENDER LOAN DETAILS MODALS  */}
          {showLoanDetailsModal && (
            <DentiModal
              key={2}
              close={() => setShowLoanDetailsModal(false)}
              modalClass='loan-details-modal modal-dialog-scrollable'
              modalClassName='loan-details-action'
              closeBtn
            >
              <LoanDetails
                loanNumber={activeLeadWsMessage?.installmentLoanNumber}
                loanDetails={loanDetails}
              />
            </DentiModal>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Leads;
