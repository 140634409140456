import { momentFormatLong } from '../../../constants';
import { currencyFormatDA, formatInterestRate } from '../../../constants/utils';
import moment from 'moment';
import React from 'react';

interface PaymentAgreementsListItemProps {
  createdDate: string;
  montlhyPayment: number;
  interestRate: number;
  remainingBalance: number;
  loanAmount: number;
}

const PaymentAgreementsListItem: React.FC<PaymentAgreementsListItemProps> = ({
  createdDate,
  montlhyPayment,
  interestRate,
  remainingBalance,
  loanAmount,
}) => {
  return (
    <>
      <div
        className='table-row-div'
        // removed loan-list-item className while we can´t click at the item for the first release version
        id='list-group-item'
        // onClick={() => openLoanDetailsModal()}
      >
        <div className='table-cell-div'>
          {moment(createdDate).format(momentFormatLong).toLowerCase()}
        </div>

        <div className='table-cell-div '>
          {montlhyPayment && currencyFormatDA(montlhyPayment, true, true)}
        </div>
        <div className='table-cell-div'>{formatInterestRate(interestRate)}</div>
        <div className='table-cell-div'>
          {remainingBalance && currencyFormatDA(remainingBalance, true, true)}
        </div>
        <div className='table-cell-div'>
          {loanAmount && currencyFormatDA(loanAmount, true, true)}
        </div>

        <div
          className='table-cell-div'
          onClick={(event) => event.stopPropagation()}
        >
          {/* hidden button for this first release version but keeping it placed to not mess up with layout*/}
          <div
          //  className='actions-menu'
          >
            <div
              color='light'
              className='KebabMenu-item'
              // onClick={() => openLoanDetailsModal()}
            >
              {/* <ArrowRightIcon className='icon' /> */}
              {/* {strings.loanDetailsTooltip} */}
            </div>
          </div>
        </div>
      </div>

      {/* Loan details modal */}
      {/* {loanPlanModal && (
        <DentiModal
          key={2}
          close={() => setLoanPlanModal(false)}
          modalClass='loan-details-modal modal-dialog-scrollable'
          modalClassName='loan-details-action'
          closeBtn
        >
          <LoanDetails loanNumber={invoice.number} loanDetails={loanPlan} />
        </DentiModal>
      )} */}
    </>
  );
};

export default PaymentAgreementsListItem;
