import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
import { currencyFormatDA } from '../../constants/utils';
import { brandConfig } from '../../config';

const StepDecline = (props) => {
  const phoneNumber =
  strings.getLanguage() === 'en'
    ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
    : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');
  return (
    <div className='step-content step-eight'>
      <h1 className='step-title'>
        {strings.stepDeclineTitle}
      </h1>
      <div className='payment-info'>
        <div>{strings.declineText}</div>
        <div>
          {strings.formatString(strings.declineText1, <b>{currencyFormatDA(props.acceptLoan.loanAmount, true, true)}</b>)}
        </div>
        <div>
          {strings.declineText2}
        </div>
      </div>
      <div className='visit-action-buttons'>
        <Button color='blue' tag='a' href={`tel:${phoneNumber}`}>
          {strings.formatString(strings.declineCallText, <br className='display-iphone5' />, phoneNumber)}
        </Button>
        <Button color='blue' tag='a' href={`mailto:${brandConfig.contactEmail}`}>
          {strings.declineEmailText}
        </Button>
      </div>
    </div>
  );
};

export default StepDecline;
