export const trackVerticalStyle = {
  position: 'absolute',
  width: '8px',
  right: '6px',
  bottom: '10px',
  top: '10px',
  borderRadius: '3px',
  // padding: '14px',
};

export const trackThinVerticalStyle = {
  position: 'absolute',
  width: '6px',
  right: '10px',
  bottom: '10px',
  top: '10px',
  borderRadius: '3px',
};

export const thumbVerticalStyle = {
  cursor: 'pointer',
  backgroundColor: '#d1d1d1',
  borderRadius: '3px',
  // width: '8px',
};

// ####### SUNDHEDPLUS #########
export const thumbVerticalCustomBackgroundStyle = {
  cursor: 'pointer',
  backgroundColor: '#e2dbd9',
  borderRadius: '3px',
};
