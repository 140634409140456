import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { strings } from '../../constants/localization';
import { getUserSessionIDReepay } from '../../actions/installmentLoanActions';

const StepSix = (props) => {
  const registerLater = (flag) => {
    props.nextStep({
      registerLater: flag,
    });
  };

  const registerPaymentCard = (data) => {
    props.nextStep({
      paymentMethod: data.payment_method,
    });
  };
  useEffect(() => {
    props.getUserSessionIDReepay(props.acceptLoan.loanNumber).then((result) => {
      const sessionData = result.payload.data;
      // eslint-disable-next-line
      const rp = new Reepay.EmbeddedCheckout(sessionData.id, { html_element: 'rp_container' });
      // eslint-disable-next-line
      rp.addEventHandler(Reepay.Event.Accept, (data) => {
        registerPaymentCard(data);
      });
    });
  });

  const shouldRegisterLater = () => {
    const { acceptLoan } = props;
    if (acceptLoan.restructured) {
      return false;
    }

    if (acceptLoan.creditCardNumber === null && acceptLoan.remainingBalanceOnExistingLoans === 0) {
      return true;
    }

    return false;
  };

  return (
    <div className='step-content step-six'>
      <h1 className='step-title'>
        {strings.step6Title}
      </h1>
      <div className='register-card'>
        {strings.registerCardText}
      </div>
      {
        shouldRegisterLater() && (
          <div className='register-card-later'>
            <Button color='link' onClick={() => registerLater(true)}>
              {strings.registerCardLater}
            </Button>
          </div>
        )
      }
      <div className='reepay-form-container'>
        <div id='rp_container' />
      </div>
    </div>
  );
};

export default connect(null, { getUserSessionIDReepay })(StepSix);
