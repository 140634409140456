import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { config } from './../../../config';
import { strings } from '../../../constants/localization';
import { moment } from '../../../constants';
import { cardStatusMap } from '../../../constants/mappings';

const PaymentStatus = (props) => {
  const { creditFacilityInformation } = props.lead;
  const [lastLidLoan, setLastLidLoan] = useState('NO');

  useEffect(() => {
    let found = false;
    props.lead.creditFacilityInformation.installmentLoanInformations
      .slice()
      .reverse()
      .forEach((item) => {
        if (
          item.status !== 'WAITING_FOR_SIGNATURE' &&
          item.status !== 'DELETED' &&
          item.status !== 'NOT_ACCEPTED' &&
          !found
        ) {
          setLastLidLoan(item.brand);
          found = true;
          return false;
        }
      });
    if (!found) {
      setLastLidLoan('NO');
    }
  }, [props.lead.creditFacilityInformation.installmentLoanInformations]);

  return (
    <Card id='PaymentStatusComponent' className='custom-card payment-status'>
      <CardBody>
        <div className='header-information'>
          <CardTitle className='header-text'>
            <div>{strings.paymentStatus}</div>
            <div>
              {lastLidLoan !== 'NO' &&
                creditFacilityInformation.subscriptionId && (
                  <div className='circle'>
                    <a
                      href={`${
                        lastLidLoan !== 'Denti'
                          ? config.reepay.Sundhedplus.replace(
                              '$0',
                              creditFacilityInformation.subscriptionId
                            )
                          : config.reepay.Denti.replace(
                              '$0',
                              creditFacilityInformation.subscriptionId
                            )
                      }`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      R
                    </a>
                  </div>
                )}
            </div>
          </CardTitle>
        </div>
        <div className='status-information'>
          <div className='line'>
            <div>{strings.latestPayment}</div>
            <div>
              {creditFacilityInformation.lastPaidInstallmentDate === null ||
              creditFacilityInformation.lastPaidInstallmentDate ===
                '1000-01-01' ? (
                <>{strings.never}</>
              ) : (
                <>
                  {moment(
                    creditFacilityInformation.lastPaidInstallmentDate
                  ).format('DD. MMMM YYYY')}
                </>
              )}
            </div>
          </div>
          <div className='line'>
            <div>{strings.cardStatus}</div>
            {creditFacilityInformation.creditCardState === null ? (
              <div className='status-yellow'>{strings.noCard}</div>
            ) : (
              <div
                className={
                  cardStatusMap[creditFacilityInformation.creditCardState]
                    .className
                }
              >
                {
                  strings[
                    cardStatusMap[creditFacilityInformation.creditCardState]
                      .statusText
                  ]
                }
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

PaymentStatus.propTypes = {
  activePage: PropTypes.number,
  searchActive: PropTypes.object,
  activeLead: PropTypes.object,
  statusActive: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    activePage: state.lead.activePage,
    searchActive: state.lead.searchActive,
    statusActive: state.status.leadStatusActive,
    allAdmins: state.user.admins,
    activeLead: state.lead.activeLead,
  };
}

export default connect(mapStateToProps, {})(PaymentStatus);
