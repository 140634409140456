import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { sortBy } from 'lodash';
import { connect } from 'react-redux';
import { strings } from '../../constants/localization';
import { moment, momentFormat } from '../../constants';
import { invoicesStatusMap } from '../../constants/mappings';
import { currencyFormatDA, formatInterestRate } from '../../constants/utils';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import { getDebtCollectionEntries } from '../../actions/installmentLoanActions';
import { toggleServerModalError } from '../../actions/uiElementsActions';

class LoanDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '2',
      latePayments: [],
    };

    this.toggle = this.toggle.bind(this);
    this.scrollRef = React.createRef();
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
  }

  componentDidMount() {
    if (this.props.loanDetails.status === 'LATE_PAYMENT') {
      this.props.getDebtCollectionEntries(this.props.loanNumber).then((res) => {
        if (res.payload.status !== 200) {
          this.props.toggleServerModalError(false);
        } else {
          this.setState({
            latePayments: res.payload.data,
            activeTab: '1',
          });
        }
      });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.resetScrollPosition();
    }
  }

  calculateDaysSincePayed(payment) {
    const dateToPay = moment(payment?.paymentDate);
    const dateWhenPayed = moment(payment?.paidDate);
    let diffAsString;
    let nextPaymentPenalty;
    let className;
    if (payment?.paidDate) {
      const diffDays = Number(dateWhenPayed.diff(dateToPay, 'days'));
      diffAsString = `${diffDays}`;
      if (diffDays <= 0) {
        className = 'green';
      } else if (diffDays > 0 && diffDays < 9) {
        className = 'grey';
      } else if (diffDays >= 9 && diffDays < 19) {
        className = 'yellow';
      } else if (diffDays >= 19) {
        className = 'red';
      }

      if (diffDays < 0) {
        diffAsString = `${diffDays}`;
      } else {
        diffAsString = `+${diffDays}`;
      }

      if (diffDays >= 9 && diffDays < 19) {
        nextPaymentPenalty = 100;
      } else if (diffDays >= 19) {
        nextPaymentPenalty = 200;
      } else {
        nextPaymentPenalty = 0;
      }

      return {
        diffAsString,
        nextPaymentPenalty,
        className,
      };
    }
    return;
  }

  // eslint-disable-next-line
  displayPaymentStatus(payment) {
    const status = payment.status;
    switch (status) {
      case 'WAITING':
        if (moment(payment.paymentDate).isAfter()) {
          return <td />;
        }
        return (
          <td className={invoicesStatusMap[payment.status].className}>
            {strings.overdue}
          </td>
        );

      case 'REEPAY':
        if (moment(payment.paymentDate).isAfter()) {
          return <td />;
        }
        return (
          <td className={invoicesStatusMap[payment.status].className}>
            {strings.overdue}
          </td>
        );

      case 'DUNNING':
      case 'FAILED':
        return (
          <td className={invoicesStatusMap[payment.status].className}>
            {strings.overdue}
          </td>
        );
      case 'PAYED':
        if (payment.paymentMethod === 'CARD_DIBS') {
          return (
            <td className={invoicesStatusMap[payment.status].className}>
              {strings.paidReepay}
            </td>
          );
        }
        if (
          payment.paymentMethod === 'ACCOUNT_TRANSFER' ||
          payment.paymentMethod === 'MOBILEPAY'
        ) {
          return (
            <td className={invoicesStatusMap[payment.status].className}>
              {strings.paidManual}
            </td>
          );
        }
        break;
      default:
        return (
          <td className={invoicesStatusMap[payment.status].className}>
            {strings[invoicesStatusMap[payment.status].statusText]}
          </td>
        );
    }
  }

  render() {
    const { loanDetails } = this.props;
    const { latePayments } = this.state;
    let totalAdditionalCosts = 0;
    const sortedPayments = sortBy(
      loanDetails.payments,
      (payment) => new Date(payment.paymentDate)
    );
    const sortedLatePayments = sortBy(
      latePayments,
      (payment) => new Date(payment.actionDate)
    );
    sortedPayments.map((payment, index, array) => {
      const result = this.calculateDaysSincePayed(payment)?.nextPaymentPenalty;
      if (result && index < array.length - 1) {
        array[index + 1].penalty = result;
        totalAdditionalCosts = totalAdditionalCosts + result;
      }
      return payment;
    });
    return (
      <Container fluid>
        <Row className='mb-4'>
          <Col xs='12' md='6'>
            <h4 className='font-weight-bold'>{strings.loanDetails}</h4>
          </Col>
          <Col xs='12' md='6' className='text-right'>
            <small className='align-middle'>{this.props.loanNumber}</small>
          </Col>
        </Row>
        <Row className='details'>
          <Col xs='12' md='6' className='border-right'>
            <div className='d-flex flex-row justify-content-between mb-1'>
              <span>{strings.loanAmount}</span>
              <span>{currencyFormatDA(loanDetails.amount, true, true)}</span>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <span>{strings.setupFee}</span>
              <span>{currencyFormatDA(loanDetails.setupFee, true, true)}</span>
            </div>
          </Col>
          <Col xs='12' md='6'>
            <div className='d-flex flex-row justify-content-between mb-1'>
              <span>{strings.interestRate}</span>
              <span>
                {`${formatInterestRate(
                  loanDetails?.loanInterestRate
                )} / ${formatInterestRate(loanDetails?.apr)}`}
              </span>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <span>{strings.loanDuration}</span>
              <span>{`${loanDetails.duration} ${strings.months}`}</span>
            </div>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col xs='12' md='12' className='loan-table'>
            <Nav tabs>
              {loanDetails.status === 'LATE_PAYMENT' && (
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === '1' ? 'active' : ''}
                    onClick={() => this.toggle('1')}
                  >
                    {strings.latePaymentCredit}
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '2' ? 'active' : ''}
                  onClick={() => this.toggle('2')}
                >
                  {strings.installments}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId='2'>
                <div className='loan-details-scroll'>
                  <Scrollbars
                    ref={this.scrollRef}
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          ...trackVerticalStyle,
                        }}
                      />
                    )}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          ...thumbVerticalStyle,
                        }}
                      />
                    )}
                  >
                    <div className='scrollbar-wrapper'>
                      <Table>
                        <tbody>
                          {sortedPayments.map((payment, index) => {
                            const diffPayedDate =
                              this.calculateDaysSincePayed(payment);
                            return (
                              <tr key={payment.id}>
                                <td>{index + 1}.</td>
                                <td className='payed-cell'>
                                  <span>
                                    {moment(payment.paymentDate).format(
                                      momentFormat
                                    )}
                                  </span>
                                </td>
                                <td className='payed-cell-diff'>
                                  {diffPayedDate && (
                                    <span
                                      className={`payed-diff ${diffPayedDate?.className}`}
                                    >
                                      {diffPayedDate?.diffAsString}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {currencyFormatDA(payment.amount, true, true)}
                                  <span className='penalty'>
                                    {payment?.penalty
                                      ? `+ ${payment?.penalty}`
                                      : ''}
                                  </span>
                                </td>
                                {this.displayPaymentStatus(payment)}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Scrollbars>
                </div>
              </TabPane>
            </TabContent>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId='1'>
                <div className='loan-details-scroll'>
                  <Scrollbars
                    ref={this.scrollRef}
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          ...trackVerticalStyle,
                        }}
                      />
                    )}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        {...props}
                        style={{
                          ...style,
                          ...thumbVerticalStyle,
                        }}
                      />
                    )}
                  >
                    <div className='scrollbar-wrapper'>
                      <Table>
                        <tbody>
                          {sortedLatePayments.map((payment, index) => (
                            <tr key={index}>
                              <td>
                                {moment(payment.actionDate).format(
                                  momentFormat
                                )}
                              </td>
                              <td>
                                <span className='has-tooltip rightArrowCenterLeft '>
                                  <i
                                    className='di di-help'
                                    onMouseEnter={() => {
                                      this.setState({
                                        tooltipOpen: true,
                                        tooltipText: payment.description,
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      this.setState({
                                        tooltipOpen: false,
                                      });
                                    }}
                                  />
                                  <span className='tooltip'>
                                    {this.state.tooltipText}
                                  </span>
                                </span>
                                {
                                  // do not show what is inside of parentheses on description
                                  payment.description?.includes('(')
                                    ? payment.description?.substring(
                                        0,
                                        payment.description?.indexOf('(')
                                      )
                                    : payment.description
                                }
                              </td>
                              {payment.type === 'DEBIT' && (
                                <td className='status-red'>
                                  {currencyFormatDA(payment.amount, true, true)}
                                </td>
                              )}
                              {payment.type === 'CREDIT' &&
                                !payment.description?.includes(
                                  'Afskrivning'
                                ) && (
                                  <td className='status-credit-green'>
                                    -
                                    {currencyFormatDA(
                                      payment.amount,
                                      true,
                                      true
                                    )}
                                  </td>
                                )}
                              {payment.type === 'CREDIT' &&
                                // first world of string is 'Late payment fee'
                                payment.description?.includes(
                                  'Afskrivning'
                                ) && (
                                  <td className='status-yellow'>
                                    -
                                    {currencyFormatDA(
                                      payment.amount,
                                      true,
                                      true
                                    )}
                                  </td>
                                )}

                              <td>
                                {currencyFormatDA(
                                  payment.newPrincipal,
                                  true,
                                  true
                                )}
                                {payment.fee > 0 && ' + ' + payment.fee}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Scrollbars>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row className='details'>
          <Col xs='12' md='5'>
            <div className='d-flex flex-row justify-content-between'>
              <span>{strings.paidInTotal}</span>
              <span className='font-weight-bold'>
                {currencyFormatDA(loanDetails.paymentSum, true, true)}
              </span>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <span>{strings.repayments}</span>
              <span>
                {currencyFormatDA(loanDetails.repaymentSum, true, true)}
              </span>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <span>{strings.interests}</span>
              <span>
                {currencyFormatDA(loanDetails.interestSum, true, true)}
              </span>
            </div>
            <div className='d-flex flex-row justify-content-between'>
              <span>{strings.additionalCosts}</span>
              <span>{currencyFormatDA(totalAdditionalCosts, true, true)}</span>
            </div>
          </Col>
          <Col xs='12' md='7' className='pt-2 pt-md-0'>
            <div className='d-flex flex-row justify-content-end'>
              {this.state.activeTab === '1' &&
                strings.formatString(
                  strings.principalAsOf,
                  moment(latePayments[0]?.actionDate).format(momentFormat)
                )}
              {this.state.activeTab === '2' &&
                strings.formatString(
                  strings.principalAsOf,
                  moment().format(momentFormat)
                )}
              :
            </div>
            <div className='d-flex flex-row justify-content-end'>
              <span className='total-as'>
                {this.state.activeTab === '1' &&
                  currencyFormatDA(latePayments[0]?.newPrincipal, true, true)}
                {this.state.activeTab === '2' &&
                  currencyFormatDA(loanDetails.remainingPrincipal, true, true)}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

LoanDetails.propTypes = {
  loanNumber: PropTypes.string,
  loanDetails: PropTypes.object,
  getDebtCollectionEntries: PropTypes.func,
};

export default connect(null, {
  getDebtCollectionEntries,
  toggleServerModalError,
})(LoanDetails);
