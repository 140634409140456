import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  requestAcceptLoanDetails,
  statusAcceptLoan,
  comfirmAcceptLoan,
  sendAgreement,
  removeCustomerReepay,
  recalculateValues,
} from '../../actions/installmentLoanActions';
import { moment } from '../../constants';
import { getGeolocation, getBrowserType } from '../../constants/utils';
import happyGuy from '../../resources/images/happy-guy.svg';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ErrorPage from '../common/ErrorPage';
import { ROUTE_HOME } from '../../constants/routes';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepAccept from './StepAccept';
import StepDecline from './StepDecline';
import StepError from './StepError';
import Fade from '../common/Fade';
import StepDeclineRest from './StepDeclineRest';
const brandEnvVariable = process.env.REACT_APP_BRAND;
class LoanAcceptance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      totalStep: 4,
      final: false,
      processData: {},
      acceptLoan: {},
      registerLater: false,
      acceptCurrentCard: false,
      firstVisit: null,
      isLoading: true,
      paymentIsLoading: false,
      renderErrorContent: false,
      loanStatus: '',
    };
    this.renderActiveStep = this.renderActiveStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.rejectLoan = this.rejectLoan.bind(this);
    this.updateProcessData = this.updateProcessData.bind(this);
    this.sendPayment = this.sendPayment.bind(this);

    this.agreement = {};
    this.reepayInvoiceSettledInterval = null;
  }

  requestLoanDetails() {
    const query = queryString.parse(this.props.location.search);

    if (query.id !== undefined) {
      this.props
        .requestAcceptLoanDetails(query.id)
        .then((result) => {
          const { data } = result.payload;

          if (data.status === 'WAITING_FOR_SIGNATURE') {
            this.setState({
              acceptLoan: data,
              totalStep: data.showQuestion ? 5 : 4,
              activeStep: data.restructured ? 2 : 1,
            });

            if (data.restructured) {
              document.body.classList.add('restructured-flow');
            }

            // this is a fix for a step 2 animation
            setTimeout(() => {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  document.body.classList.remove('loading');
                }
              );
            }, 1000);
          } else {
            this.setState({
              renderErrorContent: true,
              loanStatus: data.status,
            });
          }
        })
        .catch(() => {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              document.body.classList.remove('loading');
            }
          );
          this.errorStep();
        });
    } else {
      this.props.history.push(ROUTE_HOME);
    }
  }

  componentDidMount() {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    document.body.classList.add('public-flow');
    document.body.classList.add('public-content');
    document.body.classList.add('loading');

    this.requestLoanDetails();

    // request every 30 seconds the loan details to update the reepay settled status
    this.reepayInvoiceSettledInterval = setInterval(() => {
      if (
        this.state.acceptLoan.status === 'WAITING_FOR_SIGNATURE' &&
        this.state.acceptLoan.restructured &&
        this.state.acceptLoan.reepayInvoiceSettled === false
      ) {
        this.requestLoanDetails();
      }
    }, 30000);

    this.agreement.coordinates = {
      latitude: null,
      longitude: null,
      accuracy: null,
    };

    if (window.navigator.geolocation) {
      this.agreement.coordinates = getGeolocation(window.navigator);
    }

    this.agreement.device = getBrowserType(
      window.navigator.userAgent
    ).name.toLocaleLowerCase();
    this.agreement.installmentLoanNumber = this.state.acceptLoan.loanNumber;
  }

  componentWillUnmount() {
    document.body.classList.remove('public-flow');
    document.body.classList.remove('public-content');
    clearInterval(this.reepayInvoiceSettledInterval);
  }

  renderActiveStep() {
    window.scroll(0, 0);

    switch (this.state.activeStep) {
      case 1:
        return (
          <StepOne
            acceptLoan={this.state.acceptLoan}
            nextStep={this.nextStep}
            rejectLoan={this.rejectLoan}
          />
        );
      case 2:
        return (
          <StepTwo
            acceptLoan={this.state.acceptLoan}
            nextStep={this.nextStep}
            rejectLoan={this.rejectLoan}
            updateProcessData={this.updateProcessData}
          />
        );
      case 3:
        return (
          <StepThree
            acceptLoan={this.state.acceptLoan}
            processData={this.state.processData}
            nextStep={this.nextStep}
            rejectLoan={this.rejectLoan}
          />
        );
      case 4:
        return (
          <StepFour
            acceptLoan={this.state.acceptLoan}
            nextStep={this.nextStep}
          />
        );
      case 5:
        return (
          <StepFive
            acceptLoan={this.state.acceptLoan}
            nextStep={this.nextStep}
            processData={this.state.processData}
          />
        );
      case 6:
        return (
          <StepSix
            acceptLoan={this.state.acceptLoan}
            nextStep={this.nextStep}
          />
        );
      case 7:
        return (
          <StepAccept
            acceptLoan={this.state.acceptLoan}
            processData={this.state.processData}
            acceptCurrentCard={this.state.acceptCurrentCard}
            registerLater={this.state.registerLater}
            restructured={this.state.acceptLoan.restructured}
          />
        );
      case -1:
        if (this.state.acceptLoan.restructured) {
          return <StepDeclineRest acceptLoan={this.state.acceptLoan} />;
        }
        return <StepDecline acceptLoan={this.state.acceptLoan} />;
      case -2:
        return <StepError />;
      default:
        return <div />;
    }
  }

  nextStep(updateData = null) {
    let acceptCurrentCard = false;
    let registerLater = false;
    let paymentMethod = null;

    if (updateData !== null) {
      this.setState(updateData);

      if (updateData.acceptCurrentCard) {
        acceptCurrentCard = updateData.acceptCurrentCard;
      }

      if (updateData.registerLater) {
        registerLater = updateData.registerLater;
      }

      if (updateData.paymentMethod) {
        paymentMethod = updateData.paymentMethod;
      }
    }

    switch (this.state.activeStep) {
      case 3: {
        if (this.state.acceptLoan.showQuestion) {
          this.setState({
            activeStep: 4,
          });
        } else if (this.state.acceptLoan.creditCardNumber !== null) {
          this.setState({
            activeStep: 5,
          });
        } else {
          this.setState({
            activeStep: 6,
          });
        }
        break;
      }
      case 4: {
        if (this.state.acceptLoan.creditCardNumber !== null) {
          this.setState({
            activeStep: 5,
          });
        } else {
          this.setState({
            activeStep: 6,
          });
        }
        break;
      }
      case 5: {
        if (acceptCurrentCard) {
          this.setState({
            activeStep: 7,
            final: true,
            paymentIsLoading: true,
          });

          this.sendPayment(null, 'source');
        } else {
          this.setState({
            activeStep: 6,
          });
        }
        break;
      }
      case 6: {
        if (registerLater) {
          this.sendPayment(null, 'email');
        }

        if (paymentMethod) {
          this.sendPayment(paymentMethod, 'source');
        }
        this.setState({
          activeStep: 7,
          final: true,
          paymentIsLoading: true,
        });

        break;
      }
      case 7: {
        this.setState({
          final: true,
        });
        break;
      }
      default: {
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
        }));
        break;
      }
    }
  }

  rejectLoan() {
    this.setState({
      activeStep: -1,
      final: true,
    });

    this.props.statusAcceptLoan(this.state.acceptLoan.loanNumber, {
      status: 'NOT_ACCEPTED',
    });
  }

  errorStep() {
    this.setState(
      {
        activeStep: -2,
        final: true,
        isLoading: false,
      },
      () => {
        document.body.classList.remove('loading');
      }
    );
  }

  updateProcessData(data) {
    this.setState({
      processData: data,
    });
  }

  failureCallback = () => {
    this.setState({ paymentIsLoading: false });
    this.errorStep();
  };

  sendPayment(token, source) {
    this.props
      .recalculateValues({
        loanNumber: this.state.acceptLoan.loanNumber,
        duration: this.state.processData.duration,
      })
      .then(() => {
        this.setState({ paymentIsLoading: true });
        return this.props
          .comfirmAcceptLoan({
            reepayToken: {
              token,
              installmentLoanNumber: this.state.acceptLoan.loanNumber,
              signupMethod: source,
              creditFacilityNumber: this.state.acceptLoan.creditFacilityNumber,
            },
            clientFirstTimeAtClinic: this.state.firstVisit,
            duration: this.state.processData.duration,
          })
          .then(() => {
            this.props.sendAgreement(this.agreement);
          });
      })
      .then(() => {
        this.props.statusAcceptLoan(this.state.acceptLoan.loanNumber, {
          status: 'ACCEPTED',
        });
        //show the last step only everthing was succesfull
        this.setState({
          paymentIsLoading: false,
          activeStep: 7,
          final: true,
        });
      })
      .catch(this.failureCallback);
  }

  renderErrorContent(loanStatus) {
    const phoneNumber =
      strings.getLanguage() === 'en'
        ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
        : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');
    switch (loanStatus) {
      case 'EXPIRED':
        document.title = strings.exceptionExpiredTitle;
        return (
          <ErrorPage>
            <h1>{strings.exceptionExpiredTitle}</h1>
            <p>{strings.exceptionExpiredText1}</p>
            <p>
              {strings.formatString(
                strings.exceptionExpiredText2,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,

                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber}</a>
              )}
            </p>
          </ErrorPage>
        );
      case 'DELETED':
      case 'NOT_ACCEPTED':
        document.title = strings.exceptionDeletedTitle;
        return (
          <ErrorPage>
            <h1>{strings.exceptionDeletedTitle}</h1>
            <p>{strings.exceptionDeletedText1}</p>
            <p>{strings.exceptionDeletedText2}</p>
            <p>{strings.exceptionDeletedText3}</p>
            <p>
              {strings.formatString(
                strings.exceptionDeletedText4,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber}</a>
              )}
            </p>
          </ErrorPage>
        );
      case 'NOT_FOUND':
        return (
          <ErrorPage>
            <h1>{strings.exceptionNotFoundTitle}</h1>
            <p>{strings.exceptionNotFoundText1}</p>
            <p>
              {strings.formatString(
                strings.exceptionNotFoundText2,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber} </a>
              )}
            </p>
          </ErrorPage>
        );
      case 'ACCEPTED':
      case 'PAYED':
      case 'SETTLED':
      case 'CONSOLIDATED':
        document.title = strings.exceptionComfirmedTitle;
        return (
          <ErrorPage>
            <h1>{strings.exceptionComfirmedTitle}</h1>
            <p>{strings.exceptionComfirmedText1}</p>
            <p>{strings.exceptionComfirmedText2}</p>
            <p>
              {strings.formatString(
                strings.exceptionComfirmedText3,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber} </a>
              )}
            </p>
          </ErrorPage>
        );
      default:
        if (brandEnvVariable === 'Denti') {
          document.title = 'Denti';
        }
        if (brandEnvVariable === 'Sundhedplus') {
          document.title = 'Sundhed+';
        }
        return <div />;
    }
  }

  render() {
    console.log('this.state.activeStep', this.state.activeStep);
    const directionClassName =
      this.state.activeStep > 0 ? 'move-next' : 'move-prev';
    if (this.state.renderErrorContent) {
      return this.renderErrorContent(this.state.loanStatus);
    } else {
      return (
        <div id='acceptance-flow' className='flow-wrapper'>
          {this.state.isLoading ? (
            <LoadingMessage />
          ) : (
            <TransitionGroup
              appear
              timeout={600}
              className={`slide-group ${directionClassName} step-${
                this.state.activeStep
              } ${this.state.paymentIsLoading ? 'loading-payment' : ''}`}
            >
              <CSSTransition
                key={this.state.activeStep}
                appear
                timeout={300}
                classNames='slide'
                className='transition-container'
              >
                <Fade show={!this.state.isLoading}>
                  {brandEnvVariable === 'Denti' && (
                    <Card className='flow-container'>
                      <CardHeader>
                        {this.state.activeStep > 0 ? (
                          <div className='d-logo logo-green'>
                            <i className='di di-denti' />
                          </div>
                        ) : (
                          <div className='d-logo logo-red'>
                            <i className='di di-cancel' />
                          </div>
                        )}
                      </CardHeader>
                      <CardBody>
                        {this.state.paymentIsLoading ? (
                          <LoadingMessage message={strings.loadingMessage} />
                        ) : (
                          this.renderActiveStep()
                        )}
                      </CardBody>
                      <CardFooter>
                        {this.state.paymentIsLoading ? null : (
                          <div className='steps'>
                            {!this.state.final ? (
                              Array.from(
                                { length: this.state.totalStep },
                                (_, index) => index
                              ).map((el) => (
                                <div
                                  key={el}
                                  className={`bullet-step ${
                                    this.state.activeStep - 1 >= el
                                      ? 'active'
                                      : ''
                                  }`}
                                />
                              ))
                            ) : (
                              <p>{moment().format('DD-MM-YYYY HH:mm:ss')}</p>
                            )}
                          </div>
                        )}
                      </CardFooter>
                      {this.state.activeStep === 4 && (
                        <div className='visit-guy-container'>
                          <img
                            src={happyGuy}
                            alt='happyguy'
                            className='image-visit-guy'
                          />
                        </div>
                      )}
                    </Card>
                  )}
                  {brandEnvVariable === 'Sundhedplus' && (
                    <div className='sundhed-wrapper'>
                      <div className='sundhed-plus-logo'>
                        <img
                          className='sundhed-logo'
                          src={sundhedLogo}
                          alt='Sundhed+'
                        />
                      </div>
                      <Card className='flow-container'>
                        <CardBody>
                          {this.state.paymentIsLoading ? (
                            <LoadingMessage message={strings.loadingMessage} />
                          ) : (
                            this.renderActiveStep()
                          )}
                        </CardBody>
                        <CardFooter>
                          {!this.state.paymentIsLoading && (
                            <div className='steps'>
                              {!this.state.final ? (
                                Array.from(
                                  { length: this.state.totalStep },
                                  (_, index) => index
                                ).map((el) => (
                                  <div
                                    key={el}
                                    className={`bullet-step ${
                                      this.state.activeStep - 1 >= el
                                        ? 'active'
                                        : ''
                                    }`}
                                  />
                                ))
                              ) : (
                                <p>{moment().format('DD-MM-YYYY HH:mm:ss')}</p>
                              )}
                            </div>
                          )}
                        </CardFooter>
                        {this.state.activeStep === 4 && (
                          <div className='visit-guy-container'>
                            <img
                              src={happyGuy}
                              alt='happyguy'
                              className='image-visit-guy'
                            />
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                </Fade>
              </CSSTransition>
            </TransitionGroup>
          )}
        </div>
      );
    }
  }
}

export default connect(null, {
  requestAcceptLoanDetails,
  statusAcceptLoan,
  comfirmAcceptLoan,
  sendAgreement,
  removeCustomerReepay,
  recalculateValues,
})(LoanAcceptance);
