import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Notifications, {
  success,
  error as notifError,
} from 'react-notification-system-redux';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from './sidebar/Sidebar';
import Companies from './companies-page/Companies';
import Leads from './leads-page/Leads';
import CompanyChains from './CompanyChainsPage/CompanyChainsPage';
import PatientsDunning from './patients-dunning-page/PatientsDunning';
import Settings from './settings-page/Settings';
import AddPatientModal from './add-patient/AddPatientModal';
import SessionTimeout from './login/SessionTimeout';
import ErrorBoundary from './ErrorBoundary';
import DocumentationPage from './documentationPage/DocumentationPage';
import CreditApplicationsPage from './credit-applications/CreditApplicationsPage';
import DentiModal from '../components/common/DentiModal';
import DeleteWarningModal from '../components/common/DeleteWarningModal';
import ServerModalError from './common/ServerModalError';
import Navbar from './navbar/Navbar';

import { Sundhedplus } from '../config';
import {
  ROUTE_HOME,
  ROUTE_COMPANIES,
  ROUTE_LEADS,
  ROUTE_PATIENTS_DUNNING,
  ROUTE_COMPANY_CHAINS,
  ROUTE_SETTINGS,
  ROUTE_DOCUMENTATION,
  ROUTE_CREDIT_APPLICATIONS,
} from '../constants/routes';
import { strings } from '../constants/localization';
import styleNotification from '../constants/styleNotification';
import {
  isManager,
  isAdmin,
  isDentist,
  addNotification,
} from '../constants/utils';
import {
  moment,
  notificationDismissDuration,
  ROLE_ADMIN,
  ROLE_DENTIST,
} from '../constants';
import { connectWebsocket } from '../actions/websocketActions';
import {
  setUserLanguage,
  getAllAdmins,
  setUserFromStorage,
} from '../actions/userActions';
import { getCompaniesListForManagers } from '../actions/companyActions';
import { getCounterPendingDocuments } from '../actions/onboardingDocumentationActions';
// eslint-disable-next-line
import RegisterInvoiceModal from './register-invoice/RegisterInvoiceModal';

import ConfirmationModal from '../components/common/ConfirmationModal';
import {
  showSmsAcceptanceFlowModal,
  showSmsReactivationCreditModal,
} from '../actions/registerInvoiceActions';
import { resendSMS } from '../actions/installmentLoanActions';
import { createCreditPageBySms } from '../actions/createCreditActions';

const DAM = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = history?.location;
  const serverError = useSelector((state) => state.ui.serverError);
  const serverErrorDetails = useSelector(
    (state) => state.ui.serverErrorDetails
  );
  const notifications = useSelector((state) => state.notifications);

  const listOfAdmins = useSelector((state) => state.user?.admins);

  const showResendSmsAcceptanceInvoice = useSelector(
    (state) => state.registerInvoiceFlow.showSmsModalForAcceptanceInvoiceFlow
  );

  const showSendSmsForReactivation = useSelector(
    (state) => state.registerInvoiceFlow.showSmsModalForReactivationCredit
  );

  const patientInformationForModal = useSelector(
    (state) => state.registerInvoiceFlow.patientInformation
  );

  // when load the component need to check if showSmsAcceptancInvoice is true or undefined if is undefined check if exists on session stored showSmsAcceptanceFlowModal
  useEffect(() => {
    // Check if showResendSmsAcceptanceInvoice is undefined
    if (showResendSmsAcceptanceInvoice === undefined) {
      // Check if it exists in session storage
      const sessionValue = sessionStorage.getItem('showSmsAcceptanceFlowModal');
      if (sessionValue !== null) {
        // Dispatch an action to update the state
        dispatch(showSmsAcceptanceFlowModal(JSON.parse(sessionValue)));
      }
    }
  }, [showResendSmsAcceptanceInvoice, dispatch]);

  // when load the component need to check if showSmsReactivationCredit is true or undefined if is undefined check if exists on session stored showSmsReactivationCreditModal
  useEffect(() => {
    // Check if showSendSmsForReactivation is undefined
    if (showSendSmsForReactivation === undefined) {
      // Check if it exists in session storage
      const sessionValue = sessionStorage.getItem(
        'showSmsReactivationCreditModal'
      );
      if (sessionValue !== null) {
        // Dispatch an action to update the state
        dispatch(showSmsReactivationCreditModal(JSON.parse(sessionValue)));
      }
    }
  }, [showSendSmsForReactivation, dispatch]);

  const pendingInvoicesList =
    patientInformationForModal?.creditFacilityInformation?.installmentLoanInformations.filter(
      (invoice) => invoice.status === 'WAITING_FOR_SIGNATURE'
    );

  const lastInvoiceFromSession = sessionStorage.getItem(
    'pendingInvoiceListNumber'
  );

  const lastPendingInvoiceNumber =
    pendingInvoicesList?.length > 0
      ? pendingInvoicesList[0]?.number
      : lastInvoiceFromSession;

  const clientPhoneFromSession = JSON.parse(
    sessionStorage.getItem('clientphone')
  );
  const getClientPhone = patientInformationForModal?.leadClientInformation
    ?.phone
    ? patientInformationForModal?.leadClientInformation?.phone
    : clientPhoneFromSession;

  const companyReferenceNumber =
    patientInformationForModal?.companyReferenceNumber
      ? patientInformationForModal?.companyReferenceNumber
      : sessionStorage.getItem('companyReferenceNumber');

  const handleCloseModalToSendUserLinks = () => {
    dispatch(showSmsAcceptanceFlowModal(false));
    sessionStorage.removeItem('pendingInvoiceListNumber');
    sessionStorage.removeItem('clientphone');
    sessionStorage.removeItem('showSmsAcceptanceFlowModal');
  };

  const handleCloseModalToReactivationCredit = () => {
    dispatch(showSmsReactivationCreditModal(false));
    sessionStorage.removeItem('showSmsReactivationCreditModal');
    sessionStorage.removeItem('clientphone');
    sessionStorage.removeItem('companyReferenceNumber');
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showWarningNonActive, setShowWarningNonActive] = useState(false);
  const [websocketClient, setWebsocketClient] = useState(null);
  const [createNewInvoice, setCreateNewInvoice] = useState(false);
  let scriptExecuted = false;

  useEffect(() => {
    window.scroll(0, 0);
    document.body.classList.add('private-content');
    const lastVisitedPath = sessionStorage.getItem('lastVisitedPath');

    // if we have some path stored in the session storage, redirect to it
    if (lastVisitedPath) {
      history.replace(lastVisitedPath);
    }

    // connect websocket and save the ws client, in order to be able to disconnet on unmount
    setWebsocketClient({
      websocketClient: dispatch(connectWebsocket()).payload,
    });

    dispatch(setUserFromStorage());
    const userObj = JSON.parse(localStorage.getItem('user'));
    if (userObj !== undefined) {
      gistFunction(userObj);
      const { language } = userObj;
      if (language !== undefined) {
        dispatch(setUserLanguage(language));
        moment.locale(language);
      }

      let userRole = ROLE_DENTIST;

      for (const company in userObj.companyRoles) {
        if (
          userObj.companyRoles[company] &&
          userObj?.companyRoles[company].indexOf(ROLE_ADMIN) > -1
        ) {
          userRole = ROLE_ADMIN;
          break;
        }
      }
      // sending user data for the marketing campaign
      window.Tooltip.API.updateUserData({
        username: userObj.username,
        firstname: userObj.firstName,
        lastname: userObj.lastName,
        clinicname: userObj.companyName,
        authority: userRole,
        lastvisit: userObj.lastLoginDate || new Date(),
      });

      if (isDentist(userObj)) {
        if (userObj?.activeCompanyStatus !== 'ACTIVE') {
          setShowWarningNonActive(true);
        }
      }
      if (isManager(userObj)) {
        dispatch(getCompaniesListForManagers());
        // if company chain is NON-active then show warning message
        if (userObj?.companyChainStatus !== 'ACTIVE') {
          setShowWarningNonActive(true);
        }
      }
      if (isAdmin(userObj)) {
        if (!listOfAdmins || listOfAdmins?.length === 0) {
          dispatch(getAllAdmins());
        }
        // get counter of pending documents to show in the sidebar
        dispatch(getCounterPendingDocuments());
      }
    }

    // clean-up
    return () => {
      //disconnect websocket connection after logout
      if (websocketClient && websocketClient?.connected) {
        websocketClient.disconnect();
      }
      document.body.classList.remove('private-content');
    };
  }, []);

  useEffect(() => {
    // clean-up after changing the page
    sessionStorage.removeItem('email');
    // don't keep the menu open, after changing the page
    setMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleCreateNewInvoice = () => {
    setCreateNewInvoice(!createNewInvoice);
  };

  const gistFunction = (userObj) => {
    // Check if the script has already been executed
    if (scriptExecuted) {
      return;
    }

    try {
      if (userObj?.id) {
        _cio.identify({
          id: userObj?.id,
        });
        // Set a flag to prevent further execution
        scriptExecuted = true;
      }
    } catch (error) {
      // Handle any potential errors
      console.error('Error executing gist script:', error);
    }
  };

  return (
    <ErrorBoundary>
      <Container fluid className='app-container-height'>
        {/* NAVBAR */}
        <Row>
          <Col xs='12' md='12' lg='12' className='navbar-wrapper'>
            <Navbar
              toggleModal={toggleModal}
              modalShow={modalShow}
              toggleCreateNewInvoice={toggleCreateNewInvoice}
              createNewInvoice={createNewInvoice}
              isDashboard={false}
              userDashboard={null}
            />
          </Col>
        </Row>

        <Row>
          {/* SIDEBAR */}

          <Col
            md='12'
            lg='auto'
            className={`sidebar-wrapper ${menuOpen ? 'open' : ''}`}
          >
            <Sidebar
              toggleModal={toggleModal}
              modalIsOpened={menuOpen}
              toggleMenu={toggleMenu}
              isDashboard={false}
            />
          </Col>

          {/* MAIN CONTENT */}
          <Col xs='12' md='12' lg className='main-wrapper'>
            <Switch>
              <Route
                exact
                path={ROUTE_LEADS}
                render={(props) => (
                  <Leads
                    {...props}
                    toggleMenu={toggleMenu}
                    toggleModal={toggleModal}
                    modalShow={modalShow}
                    toggleCreateNewInvoice={toggleCreateNewInvoice}
                    createNewInvoice={createNewInvoice}
                  />
                )}
              />
              <Route
                exact
                path={ROUTE_COMPANIES}
                render={(props) => (
                  <Companies
                    {...props}
                    toggleMenu={toggleMenu}
                    toggleModal={toggleModal}
                    modalShow={modalShow}
                    toggleCreateNewInvoice={toggleCreateNewInvoice}
                    createNewInvoice={createNewInvoice}
                  />
                )}
              />

              <Route
                exact
                path={ROUTE_PATIENTS_DUNNING}
                render={(props) => (
                  <PatientsDunning
                    {...props}
                    toggleMenu={toggleMenu}
                    toggleModal={toggleModal}
                    modalShow={modalShow}
                    toggleCreateNewInvoice={toggleCreateNewInvoice}
                    createNewInvoice={createNewInvoice}
                  />
                )}
              />
              <Route
                exact
                path={ROUTE_COMPANY_CHAINS}
                render={(props) => (
                  <CompanyChains
                    {...props}
                    toggleMenu={toggleMenu}
                    toggleModal={toggleModal}
                    modalShow={modalShow}
                    toggleCreateNewInvoice={toggleCreateNewInvoice}
                    createNewInvoice={createNewInvoice}
                  />
                )}
              />
              {process.env.REACT_APP_DISABLE_DOCUMENTATION !== 'true' && (
                <Route
                  exact
                  path={ROUTE_DOCUMENTATION}
                  render={(props) => (
                    <DocumentationPage
                      {...props}
                      toggleMenu={toggleMenu}
                      toggleModal={toggleModal}
                      modalShow={modalShow}
                      toggleCreateNewInvoice={toggleCreateNewInvoice}
                      createNewInvoice={createNewInvoice}
                    />
                  )}
                />
              )}

              {process.env.REACT_APP_DISABLE_DOCUMENTATION !== 'true' && (
                <Route
                  exact
                  path={ROUTE_CREDIT_APPLICATIONS}
                  render={(props) => (
                    <CreditApplicationsPage
                      {...props}
                      toggleMenu={toggleMenu}
                      toggleModal={toggleModal}
                      modalShow={modalShow}
                      toggleCreateNewInvoice={toggleCreateNewInvoice}
                      createNewInvoice={createNewInvoice}
                    />
                  )}
                />
              )}

              <Route
                exact
                path={ROUTE_HOME}
                render={() => <Redirect to={ROUTE_LEADS} />}
              />
              <Route
                exact
                path={ROUTE_SETTINGS}
                render={(props) => (
                  <Settings
                    {...props}
                    toggleMenu={toggleMenu}
                    toggleModal={toggleModal}
                    modalShow={modalShow}
                    toggleCreateNewInvoice={toggleCreateNewInvoice}
                    createNewInvoice={createNewInvoice}
                  />
                )}
              />
              <Route path='*' render={() => <Redirect to={ROUTE_HOME} />} />
            </Switch>
          </Col>
        </Row>

        <Notifications
          notifications={notifications}
          style={styleNotification}
        />
      </Container>
      {createNewInvoice && (
        <RegisterInvoiceModal
          toggleCreateNewInvoice={toggleCreateNewInvoice}
          createNewInvoice={false}
        />
      )}

      {serverError && (
        <ServerModalError serverErrorDetails={serverErrorDetails} />
      )}
      {modalShow && <AddPatientModal toggleModal={toggleModal} />}

      {showWarningNonActive && (
        <DentiModal
          key={1}
          close={() => setShowWarningNonActive(false)}
          modalClass='warning-modal'
          modalClassName='confirm-action'
          closeBtn={false}
        >
          <DeleteWarningModal
            headlineText={strings.companyNotCooperatingWarningHeadline}
            bodyText={strings.formatString(
              strings.companyNotCooperatingWarningBody1,
              <br />
            )}
            contactText={strings.formatString(
              strings.companyNotCooperatingWarningBody2,
              <a href={`mailto:${Sundhedplus.generalEmail}`}>
                {Sundhedplus.generalEmail}
              </a>,
              <a href={`tel:${Sundhedplus.contactPhone}`}>
                {Sundhedplus.contactPhoneHtmlFormated}
              </a>,
              <br />
            )}
            close={() => setShowWarningNonActive(false)}
          />
        </DentiModal>
      )}

      {/* Resend sms with last pending invoice to acceptance flow */}
      {showResendSmsAcceptanceInvoice && (
        <DentiModal
          key={1}
          close={handleCloseModalToSendUserLinks}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              dispatch(resendSMS(lastPendingInvoiceNumber))
                .then((result) => {
                  dispatch(
                    success(
                      addNotification(
                        `${strings.successTextMessageSent} ${result.payload.data.phone}`,
                        strings.resendSMSTitleSuccess,
                        notificationDismissDuration
                      )
                    )
                  );
                  dispatch(showSmsAcceptanceFlowModal(false));
                })
                .catch(() => {
                  dispatch(
                    notifError(
                      addNotification(
                        strings.errorTextMessageSent,
                        strings.resendSMSTitleError,
                        notificationDismissDuration
                      )
                    )
                  );
                  dispatch(showSmsAcceptanceFlowModal(false));
                });
              sessionStorage.removeItem('pendingInvoiceListNumber');
              sessionStorage.removeItem('clientphone');
              sessionStorage.removeItem('showSmsAcceptanceFlowModal');
            }}
            cancelAction={handleCloseModalToSendUserLinks}
          >
            <div>
              <p className='title'>{strings.invoiceTitle}</p>
              <p className='confirm-message'>
                {`${strings.confirmActionSMS} ${getClientPhone}?`}
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}

      {/* Modal to send sms with reactivation link to new credit assesment */}
      {showSendSmsForReactivation && (
        <DentiModal
          key={1}
          close={handleCloseModalToReactivationCredit}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              dispatch(
                createCreditPageBySms(getClientPhone, companyReferenceNumber)
              )
                .then(() => {
                  dispatch(
                    success(
                      addNotification(
                        `${strings.successTextMessageSent} ${getClientPhone}`,
                        strings.resendSMSTitleSuccess,
                        notificationDismissDuration
                      )
                    )
                  );
                  dispatch(showSmsReactivationCreditModal(false));
                })
                .catch(() => {
                  dispatch(
                    notifError(
                      addNotification(
                        strings.errorTextMessageSent,
                        strings.resendSMSTitleError,
                        notificationDismissDuration
                      )
                    )
                  );
                  dispatch(showSmsReactivationCreditModal(false));
                });
              sessionStorage.removeItem('showSmsReactivationCreditModal');
              sessionStorage.removeItem('clientphone');
              sessionStorage.removeItem('companyReferenceNumber');
            }}
            cancelAction={handleCloseModalToReactivationCredit}
          >
            <div>
              <p className='title'>{strings.invoiceTitle}</p>
              <p className='confirm-message'>
                {`${strings.confirmActionSMS} ${getClientPhone}?`}
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}
      <SessionTimeout />
    </ErrorBoundary>
  );
};

export default DAM;
