import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { strings } from '../../../../constants/localization';
import GrantAccess from './GrantAccess/GrantAccess';
import './style.scss';
import ExportConfirmationModal from '../../../common/ExportConfirmationModal';
import DentiModal from '../../../common/DentiModal';
import {
  getLeadClinics,
  removeCompanyFromCreditFacility,
} from '../../../../actions/leadActions';
import { getLogsCreditFacility } from '../../../../actions/logActions';
import { moment, momentFormatLong } from '../../../../constants';
import { ROUTE_COMPANIES } from '../../../../constants/routes';
import { setCurrentCompanyRefFromRouter } from '../../../../actions/companyActions';

const ListOfAssociatedClinics = ({
  creditFacilityNumber,
  clientCpr,
  baseClinic,
  clientName,
}) => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.lead?.leadClinics);
  const [addClinic, setAddClinic] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [clinicToRemove, setClinicToRemove] = useState(null);
  const brandEnvVariable = process.env.REACT_APP_BRAND;

  useEffect(() => {
    try {
      dispatch(getLeadClinics(creditFacilityNumber));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (!addClinic) {
      try {
        dispatch(getLeadClinics(creditFacilityNumber));
      } catch (err) {
        console.log(err);
      }
    }
  }, [creditFacilityNumber]);

  const addNewClinic = () => {
    setAddClinic(true);
  };

  const removeClinic = (clinic) => {
    setShowConfirmationModal(true);
    setClinicToRemove(clinic);
  };

  const confirmRemoveAction = () => {
    dispatch(
      removeCompanyFromCreditFacility(
        clinicToRemove.companyReferenceNumber,
        clientCpr
      )
    ).then(() => {
      setShowConfirmationModal(false);
      try {
        dispatch(getLeadClinics(creditFacilityNumber));
      } catch (err) {
        console.log(err);
      }
    });
  };

  const closeAndUpdateClinicsList = () => {
    setAddClinic(false);
    dispatch(getLogsCreditFacility(creditFacilityNumber));
    dispatch(getLeadClinics(creditFacilityNumber));
  };

  return (
    <Card className='custom-card associated-clinics-component table-responsive-sm'>
      <div className='clinics-table table-size'>
        <div className='header_wrapper'>
          <div className='lead_wrapper'>
            <div className='date-field'>{strings.clinicDate}</div>
            <div className='star-field' />
            <div className='company-name'>{strings.clinicAssociated}</div>
            <div className='removeButton' />
          </div>
        </div>
        <div className='table-separator' />
        <div className='contentWrapper'>
          {clinics &&
            clinics.map((clinic) => (
              <div className='lead_row' key={clinic.companyReferenceNumber}>
                <div className='date-field'>
                  {moment(clinic.companyAssociationDate)
                    .format(momentFormatLong)
                    .toLowerCase()}
                </div>
                <div className='star-field'>
                  {baseClinic === clinic.companyReferenceNumber && (
                    <i className='lnir lnir-star-filled' />
                  )}
                </div>
                <div className='company-name'>
                  {`${clinic.companyName} ${''}`}

                  <span className='clinic-link'>
                    (
                    <Link
                      to={{
                        pathname: ROUTE_COMPANIES,
                        state: { ref: clinic.companyReferenceNumber },
                      }}
                      onClick={() =>
                        dispatch(
                          setCurrentCompanyRefFromRouter(
                            clinic.companyReferenceNumber
                          )
                        )
                      }
                    >
                      {clinic.companyReferenceNumber}
                    </Link>
                    )
                  </span>
                </div>
                <div className='removeButton'>
                  {brandEnvVariable === 'Denti' ? (
                    <Button
                      color='light'
                      className='btn-small'
                      onClick={() => removeClinic(clinic)}
                    >
                      <i className='lnir lnir-cross-circle' />
                      {strings.removeAccess}
                    </Button>
                  ) : (
                    <Button
                      className='btn-small btn-removeClinic'
                      onClick={() => removeClinic(clinic)}
                    >
                      <i className='lnir lnir-cross-circle' />
                      {strings.removeAccess}
                    </Button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className='button-area'>
        <Button color='blue' onClick={addNewClinic}>
          <i className='lnir lnir-circle-plus' />
          {strings.addclinic}
        </Button>
      </div>
      {showConfirmationModal && (
        <DentiModal
          key='export-modal'
          close={() => setShowConfirmationModal(false)}
          modalClass='confirm-modal export-modal restructure-modal'
          modalClassName='confirm-action'
        >
          <ExportConfirmationModal
            confirmAction={() => confirmRemoveAction()}
            cancelAction={() => setShowConfirmationModal(false)}
            confirmText={strings.restModalConfirm}
            cancelText={strings.restModalCancel}
          >
            <div className='associated-clinics-remove-confirmation'>
              <div className='delte-modal-title'>{strings.removeAccess}</div>
              <div className='content'>
                {strings.formatString(
                  strings.removeAccessConfirmation,
                  `${clinicToRemove?.companyName}`,
                  `${clientName}`
                )}
              </div>
            </div>
          </ExportConfirmationModal>
        </DentiModal>
      )}
      {addClinic && (
        <DentiModal
          key='export-modal'
          close={() => closeAndUpdateClinicsList()}
          modalClass='grant-access-Modal'
          modalClassName='center_modal'
        >
          <GrantAccess
            clientName={clientName}
            close={() => closeAndUpdateClinicsList()}
          />
        </DentiModal>
      )}
    </Card>
  );
};

export default ListOfAssociatedClinics;
