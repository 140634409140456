import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col } from 'reactstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { success, error as notifError } from 'react-notification-system-redux';
import { Sundhedplus } from '../../../../../config';
import ButtonLoader from '../../../../common/ButtonLoader/ButtonLoader';
import { strings } from '../../../../../constants/localization';
import { notificationDismissDuration } from '../../../../../constants';
import {
  addNotification,
  phoneNoFormat,
  websiteCheckPrefix,
  emailValidation,
  isValidURL,
} from '../../../../../constants/utils';
import { updateCompanyProfile } from '../../../../../actions/companyActions';
import { getLogsforCompanies } from '../../../../../actions/logActions';
import { companyProfileDescriptionCharsLimit } from '../../../../../config';

import '../../../../common/Select/style.scss';
import './CompanyProfile.scss';
import SwitchWithText from '../../../../common/Switch/SwitchWithText';
import DentiModal from '../../../../common/DentiModal';

const CompanyProfile = (props) => {
  const { phone, email, website, referenceNumber, companyName } =
    props.companyDetails;

  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [websiteLink, setWebsiteLink] = useState(website);
  const [companyEmail, setCompanyEmail] = useState(email);
  const [description, setDescription] = useState('');
  const [descriptionCharCounter, setDescriptionCharCounter] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [showInIndexStatus, setShowInIndexStatus] = useState(false);
  const [companySlugUrl, setCompanySlugUrl] = useState('');
  const [bookingLink, setBookingLink] = useState('');

  const [phoneError, setPhoneError] = useState('');
  const [wesbiteError, setWesbiteError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [categoriesError, setCategoriesError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const [editorHasFocus, setEditorHasFocus] = useState(false);
  const [showDisabledBookingRequestModal, setShowDisabledBookingRequestModal] =
    useState(false);
  const [showEnabledBookingRequestModal, setShowEnabledBookingRequestModal] =
    useState(false);

  const publicCompanyCategories = useSelector(
    (state) => state.company.publicCompanyCategories
  );
  const reactQuillRef = React.useRef();

  useEffect(() => {
    const company = props.companyDetails;

    setPhoneNumber(company.phone || '');
    setWebsiteLink(company.website || '');
    setCompanyEmail(company.email || '');
    setDescription(decodeURIComponent(company?.profileText || ''));
    setShowInIndexStatus(company?.showInIndex || false);
    setCompanySlugUrl(company?.companySlugUrl || '');
    setBookingLink(company?.bookingLink || '');

    let filteredSelectedCategories = [];
    if (publicCompanyCategories?.length > 0) {
      filteredSelectedCategories = publicCompanyCategories.filter((category) =>
        company?.companyCategoryNames?.includes(category.label)
      );
    }
    setCategoriesList(filteredSelectedCategories);
    setFormIsTouched(false);
    setEditorHasFocus(false);
    setCategoriesError('');
  }, [props.companyDetails, publicCompanyCategories]);

  // update char counter on quill editor and don't show characters after the limit is passed
  useEffect(() => {
    const quill = reactQuillRef.current.getEditor();
    if (quill?.getLength() > companyProfileDescriptionCharsLimit) {
      quill.setText(
        quill?.getText().slice(0, companyProfileDescriptionCharsLimit)
      );
    }
    setDescriptionCharCounter(quill?.getText().trim().length);
  }, [description]);

  const checkEditorChanged = (value, hasDeleteEvent = false) => {
    const initialValue = decodeURIComponent(props.companyDetails.profileText);
    let hasFocus = editorHasFocus;
    if (hasDeleteEvent) {
      hasFocus = true;
    }
    if (hasFocus && value !== initialValue) {
      setFormIsTouched(true);
    } else if (value === initialValue) {
      setFormIsTouched(false);
    }
  };

  const updateField = (value, field) => {
    switch (field) {
      case 'phone':
        setPhoneNumber(value);
        setPhoneError('');
        value !== phoneNumber
          ? setFormIsTouched(true)
          : setFormIsTouched(false);
        break;
      case 'website':
        setWebsiteLink(value);
        setWesbiteError('');
        value !== websiteLink
          ? setFormIsTouched(true)
          : setFormIsTouched(false);
        break;
      case 'email':
        setCompanyEmail(value);
        setEmailError('');
        value !== companyEmail
          ? setFormIsTouched(true)
          : setFormIsTouched(false);
        break;
      case 'description':
        setDescription(value);
        checkEditorChanged(value);
        break;
      default:
    }
  };

  const onChangeCategoriesHandler = (selectionArray) => {
    setCategoriesList(selectionArray);
    setFormIsTouched(true);
    setCategoriesError('');
  };

  useEffect(() => {
    if (companyEmail === '' && bookingLink === '') {
      setBookingLink('disabled');
    }
  }, [companyEmail, bookingLink]);

  const updateCompanyProfileData = (data) => {
    dispatch(updateCompanyProfile(data))
      .then((result) => {
        if (result?.status === 200) {
          setLoading(false);
          dispatch(
            success(
              addNotification(
                strings.formatString(strings.editCompanySuccess, companyName),
                strings.editCompanySuccessTitle,
                notificationDismissDuration
              )
            )
          );
          dispatch(getLogsforCompanies(data.referenceNumber));
        }
      })
      .catch(() => {
        dispatch(
          notifError(
            addNotification(
              strings.errorEditCompany,
              strings.errorEditCompanyTitle,
              notificationDismissDuration
            )
          )
        );
      });
  };

  const saveFormData = () => {
    const errors = {};
    if (!phoneNumber) {
      errors.phoneNumber = strings.required;
      setPhoneError(errors.phoneNumber);
    } else if (phoneNumber.replace(/\s+/g, '').length !== 8) {
      errors.phoneNumber = strings.danishNumber;
      setPhoneError(errors.phoneNumber);
    }
    if (websiteLink && !isValidURL(websiteLink)) {
      errors.websiteLink = strings.invalidURL;
      setWesbiteError(errors.websiteLink);
    }

    if (companyEmail && emailValidation(companyEmail)) {
      errors.companyEmail = strings.invalidEmail;
      setEmailError(errors.companyEmail);
    }

    if (categoriesList.length === 0) {
      errors.categories = strings.incompleteSelection;
      setCategoriesError(errors.categories);
    }

    if (
      bookingLink === 'disabled' &&
      companyEmail === '' &&
      Object.keys(errors).length === 0
    ) {
      setShowDisabledBookingRequestModal(true);
    }

    if (
      bookingLink !== '' &&
      companyEmail !== '' &&
      Object.keys(errors).length === 0
    ) {
      setShowEnabledBookingRequestModal(true);
      console.log('bookingLink', bookingLink);
      console.log('enableBookingRequest', showEnabledBookingRequestModal);
      console.log(
        'showDisabledBookingRequestModal',
        showDisabledBookingRequestModal
      );
    }

    if (Object.keys(errors) && Object.keys(errors).length === 0) {
      setLoading(true);
      const phoneWithoutSpaces = phoneNumber.replace(/\s+/g, '');
      const simpleListOfCategories = categoriesList.map(
        (item) => item['label']
      );
      let descriptionNotEmpty = description?.replace(' ', '');
      descriptionNotEmpty =
        descriptionNotEmpty === '<p><br></p>' ||
        descriptionNotEmpty === '<p></p>'
          ? ''
          : encodeURIComponent(description);
      const data = {
        bookingLink: bookingLink,
        companyCategories: simpleListOfCategories,
        companyCategoryNames: simpleListOfCategories,
        email: companyEmail !== email ? companyEmail : email || null,
        phone: phoneWithoutSpaces !== phone ? phoneWithoutSpaces : phone,
        profileText: descriptionNotEmpty,
        referenceNumber: referenceNumber,
        showInIndex: showInIndexStatus,
        website: websiteCheckPrefix(websiteLink) || null,
      };
      updateCompanyProfileData(data);
    } else {
      return;
    }
  };



  return (
    <>
      <div
        id='CompanyProfileComponent'
        className='profile-component custom-card'
      >
        <div className='wrapper'>
          <div className='descriptionWraper d-flex justify-content-between'>
            <div className='d-flex '>
              <div className='titleArea'>{strings.companyProfileHeading}</div>
            </div>
            <div xs='12' className='action-buttons'>
              <a
                className={`profile-link ${
                  !companySlugUrl || !isValidURL(companySlugUrl)
                    ? 'inactiveLink'
                    : ''
                }`}
                href={companySlugUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                {strings.companySeeProfileBtn}
                <i className='di di-open-new' />
              </a>
            </div>
          </div>
          <div className='mt-4 row'>
            <div className='form-wrapper col-12 col-md-12'>
              <Row className='mb-15'>
                <Col xs='12' md='12' className='col'>
                  <p className='usage-description'>
                    {strings.formatString(
                      strings.companyProfileUsage,
                      <a
                        href={Sundhedplus.presentationSite}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {strings.companyProfileUsageSiteText}
                      </a>,
                      <a
                        href={companySlugUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {strings.companyProfileUsageProfileText}
                      </a>
                    )}
                  </p>
                </Col>
              </Row>
              <Row className='mb-15'>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.companyProfileStatus}</label>
                  </div>
                  <div>
                    <SwitchWithText
                      isOn={showInIndexStatus === true ? true : false}
                      handleToggle={() => {
                        setShowInIndexStatus(!showInIndexStatus);
                        setFormIsTouched(true);
                      }}
                      onText={strings.companyActivateShowInIndexBtn}
                      offText={strings.companyDeactivateShowInIndexBtn}
                    />
                  </div>
                </Col>
              </Row>
              <Row className='mb-15'>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.companyProfilePhone}</label>
                    {phoneError && <span className='error'>{phoneError}</span>}
                  </div>
                  <input
                    type='text'
                    value={phoneNoFormat(phoneNumber) || ''}
                    maxLength={11}
                    onChange={(e) => {
                      updateField(e.target.value, 'phone');
                    }}
                  />
                </Col>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.companyProfileURL}</label>
                    {wesbiteError && (
                      <span className='error'>{wesbiteError}</span>
                    )}
                  </div>
                  <input
                    type='url'
                    value={websiteLink || ''}
                    onChange={(e) => {
                      updateField(e.target.value, 'website');
                    }}
                  />
                </Col>
              </Row>
              <Row className='mb-15'>
                <Col xs='12' md='6' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.companyProfileEmail}</label>
                    {emailError && <span className='error'>{emailError}</span>}
                  </div>
                  <input
                    type='email'
                    value={companyEmail || ''}
                    onChange={(e) => {
                      updateField(e.target.value, 'email');
                    }}
                  />
                </Col>
                <Col xs='12' md='6' className='CustomSelectComponent col'>
                  <div className='label-wrapper'>
                    <label>{strings.companyProfileCategories}</label>
                    {categoriesError && (
                      <span className='error'>{categoriesError}</span>
                    )}
                  </div>
                  <Select
                    className='custom_select'
                    classNamePrefix='custom_select'
                    options={publicCompanyCategories}
                    placeholder={
                      strings.locationFlow_step2_placeholder_categories
                    }
                    isMulti
                    value={categoriesList}
                    onChange={onChangeCategoriesHandler}
                  />
                </Col>
              </Row>
              <Row className='mb-15'>
                <Col xs='12' md='12' className='col'>
                  <div className='label-wrapper'>
                    <label>{strings.companyProfileDescription}</label>
                  </div>
                  <div className='textarea-wrapper'>
                    <ReactQuill
                      theme='snow'
                      ref={reactQuillRef}
                      value={description || ''}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                          [{ list: 'ordered' }, { list: 'bullet' }],
                          ['clean'],
                        ],
                      }}
                      bounds='.quill-editor'
                      maxLength={companyProfileDescriptionCharsLimit}
                      onChange={(htmlText) => {
                        updateField(htmlText, 'description');
                      }}
                      onKeyDown={(e) => {
                        const keyCode = e.keyCode;
                        //backspace and delete
                        if (keyCode === 8 || keyCode === 46) {
                          checkEditorChanged(e.target?.innerHTML, true);
                        } else {
                          setEditorHasFocus(true);
                        }
                      }}
                      onPaste={(e) => {
                        updateField(
                          e.clipboardData.getData('Text'),
                          'description'
                        );
                        setEditorHasFocus(true);
                      }}
                    />
                    <div className='hint d-flex justify-content-between'>
                      <span>{strings.companyProfileDescriptionHint}</span>
                      <span>
                        {descriptionCharCounter}/
                        {companyProfileDescriptionCharsLimit}{' '}
                        {strings.companyProfileDescriptionHintCounter}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className='text-center'>
            {!loading && (
              <Button
                color='blue'
                disabled={!formIsTouched}
                onClick={() => saveFormData()}
              >
                <i className='di di-save' />
                {strings.companyProfileSaveBtn}
              </Button>
            )}
            {loading && (
              <Button color='blue' disabled>
                <ButtonLoader />
              </Button>
            )}
          </div>
        </div>
      </div>

      {showDisabledBookingRequestModal || showEnabledBookingRequestModal ? (
 

        <DentiModal
          key={1}
          close={() => {
            showEnabledBookingRequestModal &&
              setShowEnabledBookingRequestModal(false);
            showDisabledBookingRequestModal &&
              setShowDisabledBookingRequestModal(false);
          }}
          modalClass='notification-modal'
          modalClassName='confirm-action'
          closeBtn={false}
        >
          <div>
            <div className='icon'>
              <i className='lnil lnil-checkmark-circle' />
            </div>
            <div>
              <p className='notification-message'>
                {showDisabledBookingRequestModal &&
                  strings.setDisabledBookingLink}
                {showEnabledBookingRequestModal &&
                  strings.setEnabledBookingLink}
              </p>
            </div>
            <div className='bottom-section action-btn cf'>
              <button
                className='submit-btn btn btn-blue center-block'
                type='button'
                onClick={() => {
                  showEnabledBookingRequestModal &&
                    setShowEnabledBookingRequestModal(false);
                  showDisabledBookingRequestModal &&
                    setShowDisabledBookingRequestModal(false);
                }}
              >
                <span>{strings.setCompanyChainActiveBtn}</span>
              </button>
            </div>
          </div>
        </DentiModal>
      ) : null}
    </>
  );
};

export default CompanyProfile;
