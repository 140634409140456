import React, { useState } from 'react';
import Error from '../../common/ErrorPage';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { useCriiptoVerify } from '@criipto/verify-react';

import {
  createMitIdState,
  isClientLogout,
  // eslint-disable-next-line
} from '../../../actions/memberPortalActions';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import ButtonLoader from '../../../components/common/ButtonLoader/ButtonLoader';

interface ErrorPageForClientPortalProps {
  setErrorEndpoint: (error: boolean) => void;
}

const ErrorPageForClientPortal: React.FC<ErrorPageForClientPortalProps> = ({
  setErrorEndpoint,
}) => {
  const { loginWithRedirect } = useCriiptoVerify();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <Error isClientPortalError>
      <h1>{strings.clientPortalErrorTitle}</h1>
      <p>{strings.clientPortalErrorP1}</p>
      <div className='button-container'>
        <Button
          className='btn btn-blue button-client-portal mt-4'
          onClick={() => {
            dispatch(isClientLogout(false));
            setLoadingButton(true);
            setErrorEndpoint(false);
            dispatch(createMitIdState() as AnyAction).then(() => {
              loginWithRedirect().then(() => {
                setLoadingButton(false);
              });
            });
          }}
        >
          {loadingButton ? <ButtonLoader /> : strings.clientPortalErrorButton}{' '}
        </Button>
      </div>
    </Error>
  );
};

export default ErrorPageForClientPortal;
