import React from 'react';
import { strings } from '../../constants/localization';
import { currencyFormatDA } from '../../constants/utils';
import { moment } from '../../constants';

const StepAccept = ({ acceptCurrentCard, registerLater, acceptLoan, processData, restructured }) => {
  const renderEmailContent = () => (
    <div className='payment-observation'>
      {strings.formatString(strings.sendEmailWithCard, <b>{acceptLoan.email}</b>)}
    </div>
  );

  const renderType = () => {
    if (acceptCurrentCard) {
      return (
        <div className='payment-observation'>
          {strings.formatString(strings.sendEmailWithCard, <b>{acceptLoan.email}</b>)}
        </div>
      );
    }
    if (registerLater) {
      return (
        <>
          <div className='payment-send-email'>
            {strings.formatString(strings.sendEmailWithoutCard, <b>{acceptLoan.email}</b>)}
          </div>
          <div className='payment-observation'>{strings.acceptObservation}</div>
        </>
      );
    }
    return renderEmailContent();
  };

  return (
    <div className={`step-content step-seven ${restructured ? 'restructured' : ''}`}>
      <h1 className='step-title'>{strings.stepAcceptTitle}</h1>
      <div className='payment-info'>
        <div>{restructured ? strings.acceptRestText : strings.acceptWithCardText}</div>
        <div>
          {strings.formatString(
            restructured ? strings.acceptRestText1 : strings.acceptWithCardText1,
            <b>{currencyFormatDA(processData.monthlyPayment.toFixed(2), true, true)}</b>,
            <b>{processData.duration}</b>
          )}
        </div>
        <div>
          {strings.formatString(
            restructured ? strings.acceptRestDueDate : strings.acceptFirstPayment,
            moment(acceptLoan.firstPaymentDate, 'YYYY-MM-DD').format('D. MMMM YYYY')
          )}
        </div>
      </div>
      {renderType()}
      <div className='check'>
        <i className='di di-circle-check' />
      </div>
    </div>
  );
};

export default StepAccept;
