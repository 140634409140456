import {
  GET_LEADS,
  GET_LEADS_BY_CATEGORY_COUNT,
  SET_CURRENT_LEAD,
  SET_OPEN_LEAD,
  PATIENT_SEARCH_EMITTED,
  SET_LEAD_ACTIVE_PAGE,
  SET_LEAD_INTERNAL_NOTE,
  LEADS_EMPTY,
  LEADS_FAILURE,
  LEADS_LOADING,
  LEADS_SUCCESS,
  EDIT_LEAD_LOADING,
  GET_LEAD_BY_CREDIT_FACILITY_ID,
  GET_LOANS_BY_CREDIT_FACILITY,
  PATIENT_UPDATE_FILTER,
  GET_LEAD_CLINICS,
  UPDATE_ACTIVE_PATIENT_INFO,
  UPDATE_ACTIVE_PATIENT_LEADS_LIST,
  SET_CLIENT_LOADING,
  SET_LOAN_DETAILS,
  UPDATE_ACTIVE_PATIENT_NOTE,
  ADD_WS_PATIENT_UPDATE,
  REMOVE_WS_PATIENT_UPDATE,
} from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_LEADS:
      return {
        ...state,
        leadsList: action.payload,
      };
    case GET_LEAD_BY_CREDIT_FACILITY_ID:
      return {
        ...state,
        lead: action.payload.data,
      };
    case GET_LEADS_BY_CATEGORY_COUNT:
      return {
        ...state,
        leadsByCategory: action.payload,
      };
    case SET_OPEN_LEAD:
    case SET_LEAD_INTERNAL_NOTE:
      return {
        ...state,
      };
    case SET_CURRENT_LEAD:
      return {
        ...state,
        activeLead: action.payload,
      };
    case SET_LEAD_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    case PATIENT_SEARCH_EMITTED:
      return {
        ...state,
        searchActive: action.payload.search,
      };
    case PATIENT_UPDATE_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_LOANS_BY_CREDIT_FACILITY:
      return {
        ...state,
        leadLoans: action.payload,
      };
    case LEADS_EMPTY:
    case LEADS_FAILURE:
    case LEADS_SUCCESS:
    case LEADS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EDIT_LEAD_LOADING:
      return {
        ...state,
        globalLoading: action.payload,
      };
    case GET_LEAD_CLINICS:
      return {
        ...state,
        leadClinics: action.payload,
      };
    case UPDATE_ACTIVE_PATIENT_INFO:
      let leadInfo = state.activeLead.leadClientInformation;
      Object.assign(leadInfo, action.payload);
      return Object.assign({}, state, {
        ...state,
        activeLead: {
          ...state.activeLead,
          leadClientInformation: leadInfo,
        },
      });
    case UPDATE_ACTIVE_PATIENT_NOTE:
      return Object.assign({}, state, {
        ...state,
        activeLead: {
          ...state.activeLead,
          internalNote: action.payload,
        },
      });
    case UPDATE_ACTIVE_PATIENT_LEADS_LIST:
      const foundIndex = state.leadsList.content.findIndex(
        (obj) => obj.id === state.activeLead.id
      );
      const newContentArray = [...state.leadsList.content]; //make a new array
      newContentArray[foundIndex] = action.payload;
      return {
        ...state,
        leadsList: {
          ...state.leadsList,
          content: newContentArray,
        },
      };
    case SET_CLIENT_LOADING:
      return {
        ...state,
        clientLoading: action.payload,
      };
    case SET_LOAN_DETAILS:
      return {
        ...state,
        wsLoanDetails: action.payload,
      };
    case ADD_WS_PATIENT_UPDATE:
      return {
        ...state,
        wsPatientsToUpdate: action.payload
          ? state.wsPatientsToUpdate.concat(action.payload)
          : state.wsPatientsToUpdate,
      };
    case REMOVE_WS_PATIENT_UPDATE:
      return {
        ...state,
        wsPatientsToUpdate: action.payload
          ? state.wsPatientsToUpdate.filter((item) => item !== action.payload)
          : state.wsPatientsToUpdate,
      };
    default:
      return state;
  }
}
