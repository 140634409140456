import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import {
  clearMemberPortalData,
  clientIsAuthenticated,
  createMitIdSession,
  getClientCreditData,
  loadingClientPortalData,
  // eslint-disable-next-line import/no-unresolved
} from 'actions/memberPortalActions';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import Navbar from '../navbar/Navbar';
import ErrorBoundary from '../ErrorBoundary';
import './style.scss';
import {
  ROUTE_MIT_SUNDHEDPLUS,
  ROUTE_MIT_SUNDHEDPLUS_HELP,
  ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
} from '../../constants/routes';
// eslint-disable-next-line
import MitDashboard from './Dashboard';
// eslint-disable-next-line
import MitMemberProfile from './MemberProfile';
import { useCriiptoVerify } from '@criipto/verify-react';

import LoadingMessage from '../../components/statusMessages/LoadingMessage';

import { useSelector, useDispatch } from 'react-redux';

// eslint-disable-next-line import/no-unresolved
import { AppDispatch } from 'redux-custom';

// eslint-disable-next-line
import ErrorPageForClientPortal from './common/ErrorPageForClientPortal';
import { config } from '../../config';

interface clientDataProps {
  clientData: {
    firstName: string;
    lastName: string;
  };
}

const SundhedplusMain: React.FC = () => {
  const { result, error } = useCriiptoVerify();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [errorEndpoint, setErrorEndpoint] = useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const loading = useSelector(
    (state: { memberPortal: { loadingClientPortalData: boolean } }) =>
      state.memberPortal?.loadingClientPortalData
  );
  const mitIdState = useSelector(
    (state: { memberPortal: { mitIdState: string } }) =>
      state.memberPortal?.mitIdState
  );
  const memberData = useSelector(
    (state: { memberPortal: { clientDataInformation: clientDataProps } }) =>
      state.memberPortal?.clientDataInformation
  );
  const authenticated = useSelector(
    (state: { memberPortal: { clientIsAuthenticated: boolean } }) =>
      state.memberPortal?.clientIsAuthenticated
  );

  const isClientLogout = useSelector(
    (state: { memberPortal: { isClientLogout: boolean } }) =>
      state.memberPortal?.isClientLogout
  );

  useEffect(() => {
    if (result && !memberData) {
      if ('id_token' in result && !errorEndpoint && !isClientLogout) {
        dispatch(createMitIdSession({ id_token: result.id_token, error: '' }))
          .then((response) => {
            if (response.status === 200) {
              dispatch(getClientCreditData())
                .then(() => {
                  dispatch(clientIsAuthenticated(true));
                  dispatch(loadingClientPortalData(false));
                })
                .catch((e) => {
                  console.log('Error', e);
                  setErrorEndpoint(true);
                  dispatch(clearMemberPortalData());
                  dispatch(loadingClientPortalData(false));
                });
            } else {
              console.log('Error', error);
              setErrorEndpoint(true);
              dispatch(clearMemberPortalData());
              dispatch(loadingClientPortalData(false));
            }
          })
          .catch((e) => {
            console.log('Error', e);
            setErrorEndpoint(true);
            dispatch(clearMemberPortalData());
            dispatch(loadingClientPortalData(false));
          });
      } else {
        dispatch(clearMemberPortalData());
        dispatch(loadingClientPortalData(false));
      }
    } else {
      dispatch(loadingClientPortalData(false));
    }
  }, [authenticated, loading, error, result, dispatch, errorEndpoint]);

  //every time user refresh the page and are already authenticated call the endpoint again to get the data
  useEffect(() => {
    loadingClientPortalData(true);
    if (authenticated) {
      dispatch(getClientCreditData())
        .then(() => {
          dispatch(loadingClientPortalData(false));
        })
        .catch(() => {
          dispatch(clearMemberPortalData());
          dispatch(loadingClientPortalData(false));
          setErrorEndpoint(true);
        });
    }
  }, []);

  useEffect(() => {
    if (
      mitIdState === '' &&
      !authenticated &&
      !loading &&
      !error &&
      !errorEndpoint
    ) {
      dispatch(loadingClientPortalData(false));
      window.location.href = config.damAppMainPageUrl;
    }
  }, [mitIdState, history, error, dispatch]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  useEffect(() => {
    document.body.classList.add('private-content');

    return () => {
      document.body.classList.remove('private-content');
    };
  }, []);

  // detect if the user are using a IOS device and prevent to zoon in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        true
      );
    }

    document.body.classList.add('private-content');
    return () => {
      document.body.classList.remove('private-content');
    };
  }, []);

  useEffect(() => {
    // Function to set the vh unit exactly to the viewport height, accounting for the mobile browser bar
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set vh unit on load
    setVh();

    // Set vh unit on resize
    window.addEventListener('resize', setVh);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', setVh);
  }, []);

  const { firstName, lastName } = memberData?.clientData || {
    firstName: '',
    lastName: '',
  };

  const areErrors = error || (errorEndpoint && !loading && !authenticated);

  return (
    <>
      <ErrorBoundary>
        {authenticated && (
          <Container fluid id='mitSundhedPlus' className='app-container-height'>
            <Row>
              <Col xs='12' md='12' lg='12' className='navbar-wrapper'>
                <Navbar
                  isDashboard
                  toggleModal={toggleModal}
                  modalShow={modalShow}
                  toggleCreateNewInvoice={null}
                  createNewInvoice={null}
                  userDashboard={`${firstName} ${lastName}`}
                />
              </Col>
            </Row>
            <Row>
              <Col
                md='12'
                lg='auto'
                className={`sidebar-wrapper dashboard-sidebar ${
                  menuOpen ? 'open' : ''
                }`}
              >
                <Sidebar
                  toggleModal={toggleModal}
                  modalIsOpened={menuOpen}
                  toggleMenu={toggleMenu}
                  isDashboard
                />
              </Col>
              <Col xs='12' md='12' lg className='main-wrapper'>
                <Switch>
                  <Route
                    exact
                    path={ROUTE_MIT_SUNDHEDPLUS}
                    render={() => <MitDashboard toggleMenu={toggleMenu} />}
                  />
                  <Route
                    exact
                    path={ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE}
                    render={() => <MitMemberProfile toggleMenu={toggleMenu} />}
                  />
                  <Route
                    exact
                    path={ROUTE_MIT_SUNDHEDPLUS_HELP}
                    render={() => <div>Help</div>}
                  />
                </Switch>
              </Col>
            </Row>
          </Container>
        )}
        {areErrors ? (
          <ErrorPageForClientPortal setErrorEndpoint={setErrorEndpoint} />
        ) : (
          <LoadingMessage />
        )}
      </ErrorBoundary>
    </>
  );
};

export default SundhedplusMain;
