import React, { Component } from 'react';
import TransitionGroup from 'react-transition-group/CSSTransition';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

class DentiModal extends Component {
  constructor(props) {
    super(props);

    this.close = this.props.close;
  }

  render() {
    const modalClass = this.props.modalClass
      ? `${this.props.modalClass} modal-container denti-modal`
      : 'modal-container denti-modal';
    const child = (
      <div>
        <Modal
          isOpen
          toggle={this.close}
          className={modalClass}
          modalClassName={this.props.modalClassName}
          backdrop={this.props.backdrop}
          keyboard={this.props.keyboard}
          autoFocus={false}
        >
          {this.props.closeBtn && (
            <span className='close' onClick={this.close}>
              <i className='lnir lnir-close' />
            </span>
          )}
          <ModalBody>{this.props.children}</ModalBody>
        </Modal>
      </div>
    );

    return (
      <TransitionGroup appear exit timeout={600}>
        {child}
      </TransitionGroup>
    );
  }
}

DentiModal.propTypes = {
  close: PropTypes.func,
  modalClassName: PropTypes.string,
  modalClass: PropTypes.string,
  children: PropTypes.node,
};

export default DentiModal;
