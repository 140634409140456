import { requestAxios as axios } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import * as constants from '../constants';

export function createInstallmentLoans(values) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/invoice/post4`,
    data: values,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  })
    .then((response) => ({
      type: constants.CREATE_INSTALLMENT_LOAN_SUCCESS,
      payload: response,
    }))
    .catch((err) => ({
      type: constants.CREATE_INSTALLMENT_LOAN_SUCCESS,
      payload: { errorText: err.response.data.cause },
    }));

  return {
    type: constants.CREATE_INSTALLMENT_LOAN_SUCCESS,
    payload: request,
  };
}

export function removeResponseInstallmentLoans() {
  return {
    type: constants.CREATE_INSTALLMENT_LOAN_SUCCESS,
    payload: null,
  };
}

export function resendSMS(loanNumber) {
  const request = axios({
    method: 'put',
    url: `${constants.SERVER_URL}/api/v1/invoice/resend/sms/${loanNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.RESEND_SMS,
    payload: request,
  };
}

export function resendEmail(loanNumber) {
  const request = axios({
    method: 'put',
    url: `${constants.SERVER_URL}/api/v1/invoice/resend/email/${loanNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.RESEND_EMAIL,
    payload: request,
  };
}

export function cloneInvoice(invoiceDetails) {
  return {
    type: constants.CLONE_INVOICE,
    payload: invoiceDetails,
  };
}

export function getInstallmentByNumber(number) {
  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/api/v2/installmentloans/${number}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.GET_INSTALLMENT,
    payload: request,
  };
}

export function getLoanDetails(loanNumber) {
  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/api/v1/installmentloans/${loanNumber}/paymentplan`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.GET_LOAN_DETAILS,
    payload: request,
  };
}

export function getDebtCollectionEntries(loanNumber) {
  return () =>
    axios({
      method: 'post',
      url: `${constants.SERVER_URL}/api/v1/debtCollection/getDebtCollectionEntries`,
      data: {
        loanNumber,
      },
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
    })
      .then((response) => {
        if (response.status === 200) {
          return {
            type: constants.GET_DEBT_COLLECTION_ENTRIES,
            payload: response,
          };
        }
      })
      .catch((err) => ({
        type: constants.GET_DEBT_COLLECTION_ENTRIES,
        payload: err,
      }));
}

export function deleteInstallmentLoan(number) {
  const request = axios({
    method: 'delete',
    url: `${constants.SERVER_URL}/api/v1/installmentloans?number=${number}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.DELETE_INSTALLMENT_LOAN,
    payload: request,
  };
}

export function getDebtLoans(page = 0, size = 18) {
  const paramsRequest = {};

  if (page > 0) {
    paramsRequest.page = page;
    paramsRequest.size = size;
  } else {
    paramsRequest.page = 0;
    paramsRequest.size = size;
  }

  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/api/v1/debtCollectionLoan`,
    params: paramsRequest,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.GET_DEBT_COLLECTION_LOANS,
    payload: request,
  };
}

export function setActivePage(activePage) {
  return {
    type: constants.SET_LOAN_ACTIVE_PAGE,
    payload: activePage,
  };
}

export function registerDebtLoan(data) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/debtCollectionLoan`,
    data,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.REGISTER_DEBT_COLLECTION_LOANS,
    payload: request,
  };
}

export function requestAcceptLoanDetails(loanId) {
  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/public/installmentloan/getLoanDetails`,
    params: {
      id: loanId,
    },
  });
  return {
    type: constants.REQUEST_ACCEPT_LOAN_DETAILS,
    payload: request,
  };
}

// accept loan
export function statusAcceptLoan(loanNumber, status) {
  const params = {
    ...status,
    loanNumber,
  };
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/public/installmentloan/status`,
    data: params,
  });

  return {
    type: constants.DECLINE_ACCEPT_LOAN,
    payload: request,
  };
}

export function comfirmAcceptLoan(paymentInfo) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/public/api/v1/reepay/payment`,
    data: paymentInfo,
  });

  return {
    type: constants.COMFIRM_ACCEPT_LOAN,
    payload: request,
  };
}

export function sendAgreement(agreement) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/public/api/v1/agreement`,
    data: agreement,
  });

  return {
    type: constants.SEND_AGREEMENT,
    payload: request,
  };
}

export function getUserSessionIDReepay(installmentLoanNumber) {
  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/public/createUserAndGetSessionId/${installmentLoanNumber}`,
  });
  return {
    type: constants.CREATE_CUSTOMER_SESSIONID_REEPAY,
    payload: request,
  };
}

export function removeCustomerReepay(installmentLoanNumber) {
  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/public/deleteCustomerFromReepay/${installmentLoanNumber}`,
  });
  return {
    type: constants.REMOVE_CUSTOMER_REEPAY,
    payload: request,
  };
}

export function recalculateValues(data) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/public/installmentloan/recalculateLoanValues`,
    data,
  });
  return {
    type: constants.RECALCULATE_VALUES,
    payload: request,
  };
}

export function settleLoan(loanNumber) {
  const request = axios({
    method: 'put',
    url: `${constants.SERVER_URL}/api/v1/installmentloan/settled?number=${loanNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.SETTLE_LOAN,
    payload: request,
  };
}

export function getLoanSignatureData(loanNumber) {
  const request = axios({
    method: 'get',
    url: `${constants.SERVER_URL}/api/v1/installmentloans/getLoanSignatureData/${loanNumber}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.GET_LOAN_SIGNATURE_DATA,
    payload: request,
  };
}
