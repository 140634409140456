import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { throttle } from 'lodash';
import Countdown from 'react-countdown';
import { brandConfig } from '../../config';
import { toggleServerModalError } from '../../actions/uiElementsActions';
import { isAuthenticated, getCredentialsFromURL } from '../../constants/utils';
import { ROUTE_HOME } from '../../constants/routes';
import {
  userLogin,
  setUserLockdown,
  errorAuthUser,
} from '../../actions/userActions';
import { strings } from '../../constants/localization';
import ServerModalError from '../common/ServerModalError';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import { ReactComponent as EnvelopeIcon } from '../../resources/icons/envelope-alt.svg';
import { ReactComponent as LockIcon } from '../../resources/icons/lock-alt.svg';

const Login = ({ switchToForgotPassword, resetPassordEmail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const queryString = search?.substring(1);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  let loginTimerHandle = useRef();
  const isAuth = useSelector((state) => state.user?.isAuth);
  const hasError = useSelector((state) => state.user?.hasError);
  const hasAuthority = useSelector((state) => state.user?.hasAuthority);
  const userLockdown = useSelector((state) => state.user?.userLockdown);
  const serverError = useSelector((state) => state.ui?.serverError);
  const serverErrorDetails = useSelector(
    (state) => state.ui?.serverErrorDetails
  );
  const [showResetPasswordText, setShowResetPasswordText] = useState(false);

  const showSessionExpiredWarning = JSON.parse(
    localStorage.getItem('showSessionExpiredWarning')
  );
  const brandEnvVariable = process.env.REACT_APP_BRAND;
  const notCooperating = !!(
    serverError &&
    serverErrorDetails.status === 403 &&
    serverErrorDetails.data?.cause?.includes('Access is denied: Company status')
  );
  const wrongBrand = !!(
    serverError &&
    serverErrorDetails.status === 403 &&
    serverErrorDetails.data?.cause?.includes(
      'Access is denied: Trying to login to the wrong brand'
    )
  );

  const serverIsDown =
    typeof serverErrorDetails !== 'object' ||
    serverErrorDetails === 'Network Error';

  // detect if the user are using a IOS device then prevent the zoom in/out
  useEffect(() => {
    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    if (isAuthenticated()) {
      throttle(() => {
        history.push(ROUTE_HOME);
      }, (30 * 1000) / 100);
    }
    if (queryString) {
      const credentials = getCredentialsFromURL(queryString);
      formik.setFieldValue('username', credentials?.username);
      formik.setFieldValue('password', credentials?.password);
      login(credentials);
    }

    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
      if (loginTimerHandle.current) {
        clearTimeout(loginTimerHandle.current);
        dispatch(toggleServerModalError(false));
      }
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      history.push(ROUTE_HOME);
    }
  }, [isAuth, history]);

  useEffect(() => {
    if (resetPassordEmail) {
      setShowResetPasswordText(true);
      setTimeout(() => {
        setShowResetPasswordText(false);
      }, 2000);
    }
  }, [resetPassordEmail]);

  const login = useCallback(
    (values) => {
      // don't permit double click on login button
      setIsLoginButtonDisabled(true);
      dispatch(
        userLogin({
          username: values.username,
          password: values.password,
        })
      ).then(() => {
        // if the login failed, let the user try again
        localStorage.removeItem('showSessionExpiredWarning');
        loginTimerHandle.current = setTimeout(() => {
          setIsLoginButtonDisabled(false);
        }, 1000);

        // set a timeout to remove session lastVisitedPath, 3 second after the user has been logged in
        setTimeout(() => {
          const lastVisitedPath = sessionStorage.getItem('lastVisitedPath');
          if (lastVisitedPath) {
            sessionStorage.removeItem('lastVisitedPath');
          }
        }, 3000);
      });
    },
    [dispatch]
  );

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validateOnMount: true,
    validate: (values) => {
      const errors = {};
      const { username, password } = values;

      if (!username) {
        errors.username = strings.required;
      }

      if (!password) {
        errors.password = strings.required;
      }

      return errors;
    },
    onSubmit: (values) => {
      login(values);
    },
  });

  const returnToForm = () => {
    if (userLockdown > 0) {
      dispatch(setUserLockdown(0));
      dispatch(errorAuthUser());
    }
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const renderCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      returnToForm();
    }
    // Render a countdown
    return (
      <strong>
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </strong>
    );
  };

  return (
    <>
      <div id={`${brandEnvVariable}`} className='user-login'>
        <div className='form-wrapper'>
          <form onSubmit={formik.handleSubmit} id='loginForm' className='cf'>
            <h2>{strings.loginHeadline}</h2>
            {showResetPasswordText && resetPassordEmail && (
              <p className='login-newlink-message pb-1'>
                {strings.formatString(
                  strings.loginMessageWithNewLink,
                  resetPassordEmail
                )}
              </p>
            )}
            {/* USERNAME */}
            <div
              className={`form-group username ${
                focusedField === 'username' ? 'active' : ''
              } ${
                formik.touched.username && formik.errors.username
                  ? 'has-error'
                  : ''
              }`}
            >
              <EnvelopeIcon />
              <label htmlFor='username'>{strings.username}</label>
              <input
                type='text'
                id='username'
                className={`form-group ${
                  formik.touched.username ? 'has-error' : ''
                }`}
                name='username'
                placeholder={strings.username}
                disabled={isLoginButtonDisabled}
                onChange={formik.handleChange}
                onBlur={() => handleBlur()}
                onFocus={() => handleFocus('username')}
                value={formik.values.username}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='text-error'>{formik.errors.username}</div>
              )}
            </div>

            {/* PASSWORD */}
            <div
              className={`form-group password ${
                focusedField === 'password' ? 'active' : ''
              } ${
                formik.touched.password && formik.errors.password
                  ? 'has-error'
                  : ''
              }`}
            >
              <LockIcon />
              <label htmlFor='password'>{strings.password}</label>
              <input
                type='password'
                id='password'
                className={`form-group ${
                  formik.touched.password ? 'has-error' : ''
                }`}
                name='password'
                placeholder={strings.password}
                disabled={isLoginButtonDisabled}
                onChange={formik.handleChange}
                onBlur={() => handleBlur()}
                onFocus={() => handleFocus('password')}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='text-error'>{formik.errors.password}</div>
              )}
            </div>

            <div
              className='forgot-password'
              onClick={() => {
                switchToForgotPassword('forgotPassword');
              }}
            >
              {strings.forgotPassword}
            </div>

            {userLockdown > 0 ? (
              <div className='login-bottom'>
                <div className='help'>
                  <p>{strings.tooManyLoginAttempts}</p>
                  <p>
                    {strings.formatString(
                      strings.waitToRetry,
                      <a>{strings.resetPasswordToUnlock}</a>
                    )}
                  </p>
                  <Countdown
                    date={Date.now() + userLockdown}
                    renderer={renderCountdown}
                    //onComplete={returnToForm()}
                  />
                </div>
              </div>
            ) : (
              <>
                {hasAuthority === false && (
                  <p className='login-error-message'>
                    {strings.noAuthorityMsg}
                  </p>
                )}
                {hasError && (
                  <p className='login-error-message'>
                    {serverIsDown && strings.loginServerDownMessage}
                    {wrongBrand &&
                      strings.formatString(
                        strings.loginWrongBrand,
                        <a href={`${brandConfig.mainPageUrlLoginWrongBrand}`}>
                          {brandConfig.mainPageUrlLoginWrongBrand}
                        </a>
                      )}
                    {notCooperating && strings.loginErrorNotCooperating}
                    {!wrongBrand && !notCooperating && !serverIsDown && (
                      <>
                        <p>
                          {strings.invalidPassword1}
                          <br />
                          {strings.invalidPassword2}
                        </p>
                      </>
                    )}
                  </p>
                )}

                {showSessionExpiredWarning && (
                  <p className='login-warning-message'>
                    {strings.sessionExpiredHeadline} <br />
                    {strings.sessionExpiredText}
                  </p>
                )}
              </>
            )}

            <button type='submit' disabled={isLoginButtonDisabled}>
              {isLoginButtonDisabled ? <ButtonLoader /> : strings.login}
            </button>
          </form>
        </div>
      </div>
      {serverError &&
        serverErrorDetails &&
        serverErrorDetails.status !== 400 &&
        serverErrorDetails.status !== 500 &&
        serverErrorDetails.status !== 423 &&
        serverErrorDetails.status !== 403 && (
          <ServerModalError serverErrorDetails={serverErrorDetails} />
        )}
    </>
  );
};

export default Login;
