import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'reactstrap';
import {
  isDeletedOrNotAcceptedOrWaiting,
  currencyFormatDA,
} from '../../constants/utils';
import { strings } from '../../constants/localization';
import {
  trackThinVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PaymentAgreementsListItem from './PaymentAgreementsListItem';

const PaymentAgreementsList = (props) => {
  const scrollRef = useRef(null);
  const [lead, setLead] = useState(props.lead);
  const [creditDetails, setCreditDetails] = useState(
    lead.creditFacilityInformation || {}
  );
  const [intallmentLoans, setIntallmentLoans] = useState(
    creditDetails.installmentLoanInformations.filter(
      (invoice) => !isDeletedOrNotAcceptedOrWaiting(invoice.status)
    )
  );
  const [consolidatedCounter, setConsolidatedCounter] = useState(0);

  useEffect(() => {
    const currentPatient = props.lead;
    const creditDetails = currentPatient.creditFacilityInformation;
    const availableInvoices = creditDetails.installmentLoanInformations.filter(
      (invoice) => !isDeletedOrNotAcceptedOrWaiting(invoice.status)
    );
    availableInvoices.forEach((invoice) => {
      if (
        invoice.status === 'RESTRUCTURED' ||
        invoice.status === 'CONSOLIDATED'
      ) {
        setConsolidatedCounter(consolidatedCounter + 1);
      }
    });
    setLead(currentPatient);
    setCreditDetails(creditDetails);
    setIntallmentLoans(availableInvoices);
  }, [props.lead]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollRef.current !== null) {
        scrollRef.current.scrollToBottom({ behavior: 'smooth' });
      }
    }, 10);
  };

  const renderAgreementsList = () => {
    const reversedLoans = [...intallmentLoans].reverse();
    let currentGroup = null;
    const structuredLoans = [];

    reversedLoans?.forEach((invoice) => {
      let status = invoice.status;

      if (
        invoice.status === 'RESTRUCTURED' ||
        invoice.status === 'CONSOLIDATED'
      ) {
        status = 'GROUPED';
      }

      if (status !== currentGroup) {
        currentGroup = status;
        structuredLoans.push([]);
      }

      structuredLoans[structuredLoans.length - 1].push(invoice);
    });

    return structuredLoans.map((group, index) => {
      return (
        <div
          key={index}
          className={`${
            group[0]?.status === 'CONSOLIDATED'
              ? 'consolidated-group'
              : 'status-group'
          }
              ${structuredLoans.length === index + 1 ? 'last' : ''}`}
        >
          {group.map((invoice, invoiceIndex) => (
            <PaymentAgreementsListItem
              key={invoice.id}
              invoice={invoice}
              lead={lead}
              firstInGroup={invoiceIndex === 0}
              lastInGroup={invoiceIndex === group.length - 1}
              onlyOne={group.length === 1}
              {...(reversedLoans.length > 5 && index >= reversedLoans.length - 4
                ? { scrollToBottom: scrollToBottom }
                : {})}
            />
          ))}
        </div>
      );
    });
  };

  return (
    <Card className='invoice-history custom-card  mb-3'>
      {/* LOAN AGREEMENTS TABLE  */}
      <div className='header-information agreement my-3'>
        <h4>{strings.agreementLoanHeading}</h4>
        <div className='credit-status'>
          <span className='label'>{strings.agreementRemainingAmount}</span>
          <span className='remaining-amount'>
            <i className='lnir lnir-coin' />
            {currencyFormatDA(
              lead.creditFacilityInformation.remainingBalanceForAllLoans,
              true,
              true
            )}
          </span>
        </div>
      </div>
      <div
        className={`custom-table agreement table-responsive-sm table-wrapper ${
          intallmentLoans.length === 0 ? 'empty' : ''
        }`}
      >
        <iframe id='iframeContentToPrint' title='iframeContentToPrint' />
        <div className='table-header'>
          <div className='table-row-div'>
            <div className='table-cell-div'>
              {strings.agreementLoanStartDate}
            </div>
            <div className='table-cell-div'>{strings.agreementLoanType}</div>
            <div className='table-cell-div'>{strings.agreementLoanStatus}</div>
            <div className='table-cell-div'>
              {strings.agreementLoanInterestRate}
            </div>
            <div className='table-cell-div'>
              {strings.agreementLoanRemainingPrincipal}
            </div>
            <div className='table-cell-div'>{strings.agreementLoanAmount}</div>
            <div className='table-cell-div'></div>
          </div>
        </div>
        <div className='table-separator' />
        {intallmentLoans && intallmentLoans.length > 0 && (
          <div
            className={`table-body invoices-list invoices-list-${
              intallmentLoans.length > 5 ? 'scroll' : intallmentLoans.length
            }`}
          >
            {intallmentLoans.length > 5 ? (
              <Scrollbars
                ref={scrollRef}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{ ...style, ...trackThinVerticalStyle }}
                  />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
                )}
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    style={{ display: 'none' }}
                    className='track-horizontal'
                  />
                )}
              >
                <div className='scrollbar-wrapper'>
                  {renderAgreementsList()}
                </div>
              </Scrollbars>
            ) : (
              <div className='scrollbar-wrapper'>{renderAgreementsList()}</div>
            )}
          </div>
        )}
        <div
          className={`card-table-footer agreement ${
            intallmentLoans.length > 5 ? 'has-scroll' : ''
          } `}
        ></div>
      </div>
      {/* END LOAN AGREEMENTS TABLE  */}
    </Card>
  );
};
export default PaymentAgreementsList;
