import { strings } from '../../../constants/localization';
import React from 'react';
import { Card, CardBody } from 'reactstrap';

const DocumentationCard: React.FC = () => {
  const handleAction = () => {
    // open this document in a different tab    Sundhedplus.termsAndConditionsWithoutBnpl
    window.open('https://sundhedplus.dk/assets/aftalebestemmelser.html');
  };

  return (
    <Card className='custom-card'>
      <CardBody className='credit-info-card'>
        <div className='title'>{strings.mitDocumentSectionTitle}</div>
        <div className='content'>
          <ul>
            <li onClick={handleAction}>{strings.mitCreditTermsLabel}</li>
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default DocumentationCard;
